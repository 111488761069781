import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    titleError: {
        color: "red",
        fontWeight: "bold",
        fontSize: "22px",
    },
    titleNormal: {
        color: "green",
        fontWeight: "bold",
        fontSize: "22px",
    },
}));

const OkDialog = (props) => {
    const { okDialog, setOkDialog } = props;
    const classes = useStyles();
    return (
        <Dialog open={okDialog.isOpen} PaperProps={{ sx: { position: "fixed", top: 100 } }}>
            <DialogTitle>
                <Typography sx={{ textAlign: "center", fontSize: "14px" }} className={okDialog.isError ? classes.titleError : classes.titleNormal}>
                    {okDialog.title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ textAlign: "center" }}>{okDialog.subTitle}</Typography>
            </DialogContent>
            <DialogActions sx={{ textAlign: "center" }}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ textAlign: "center" }}>
                                    <Button variant="contained" onClick={okDialog.onConfirm}>
                                        Ok
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogActions>
        </Dialog>
    );
};

export default OkDialog;
