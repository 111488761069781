import { Box, CardHeader, Container, getNativeSelectUtilityClasses, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CustomerLayout } from "./layouts/dashboard/customerlayout";
import { League } from "../sections/league";
import { Pick } from "../sections/pick";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../actions/packages";
import { logout } from "../redux/customerSlice.js";
import { useNavigate } from "react-router-dom";
import { getPicks } from "../actions/picks";

const Page = (props) => {
    const token = useSelector((state) => state.customer.token);
    const email = useSelector((state) => state.customer.email);
    const session = useSelector((state) => state.customer.sessionId);
    const [picks, setPicks] = useState([]);
    const [hasActivePackage, setHasActivePackage] = useState(false);
    const [picksToShow, setPicksToShow] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [selectButton, setSelectButton] = useState();
    const [actualLeague, setActualLeague] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChangeLeague = (id) => {
        //console("FILTER PICKS", id);
        if (id === "-1") {
            setPicksToShow(picks);
        } else {
            var tempArray = picks.filter((element) => element.league.toString() === id.toString());
            setPicksToShow(tempArray);
        }
    };

    useEffect(() => {
        dispatch(
            getPicks(email, session, token, (data) => {
                //console(data);
                if (!data.error) {
                    if (data.data.hasActivePackage === true && data.data.picks.length === 0) {
                        setHasActivePackage(true);
                        setPicksToShow([]);
                    } else if (data.data.picks.length === 0) {
                        navigate("/packages");
                    } else {
                        setPicks(data.data.picks);
                        setPicksToShow(data.data.picks);
                        setLeagues(data.data.leagues);
                    }
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    console.log("hasActivePackage", hasActivePackage);
    return picksToShow ? (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0,
                }}
            >
                <Container maxWidth="xl">
                    <Typography color="text.secondary" variant="overline" style={{ fontSize: "24px", color: "black" }}>
                        {"Picks"}
                    </Typography>
                    {hasActivePackage === true ? (
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={12} lg={12} mt={5} key={"nopicks"} sx={{ height: "100%", verticalAlign: "center" }}>
                                <Typography color="text.secondary" variant="overline" style={{ fontSize: "14px", color: "black", verticalAlign: "center", marginBottom: "100px" }}>
                                    No picks available at this moment.
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <Grid container spacing={3} justifyContent="center">
                                {leagues.map((lg, key) => {
                                    return (
                                        <Grid item xs={12} sm={6} lg={3} mt={0} key={key}>
                                            <League sx={{ height: "100%" }} id={lg._id} name={lg.name} image={lg.image} handleChangeLeague={handleChangeLeague} setSelectButton={setSelectButton} selectButton={selectButton} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Grid container spacing={1} justifyContent="center">
                                {picksToShow.map((pck, key) => {
                                    return (
                                        <Grid item xs={12} sm={6} lg={12} mt={0} key={key}>
                                            <Pick sx={{ height: "100%" }} pick={pck.pick} pickAnalisis={pck.pickAnalisis} league={pck.league} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </>
                    )}
                </Container>
            </Box>
        </>
    ) : null;
};

Page.getLayout = (page) => <CustomerLayout>{page}</CustomerLayout>;
export default Page;
