import { useNavigate } from "react-router-dom";
import * as api from "../api/security.js";
import { login, updateTwoFactor } from "../redux/customerSlice.js";

export const verifyTwoFactCode = (info, history, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.verifyTwoFactCode(info, token);
        //console.log("VERIFICAR FROM ACTIONS", data);
        if (!data.error) {
            //console.log("DATA VERIFICATION", data);
            if (info.fromLogin && data.data.verified) {
                await dispatch(login(data));
                const navigate = useNavigate();
                if (data.isAdmin) {
                    navigate("/adminorderslist");
                } else {
                    //console.log("Navigate to");
                    navigate("/picks");
                }
            } else {
                if (data.data.verified === true) {
                    dispatch(updateTwoFactor(data));
                }
                callback(data);
            }
        } else {
            callback(data);
        }
    } catch (error) {}
};

export const updateTwoFactorSecurity = (info, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.updateTwoFactorSecurity(info, token);
        callback(data);
    } catch (error) {}
};

export const getTwoFactorInfoSecurity = (email, token, callback) => async (dispatch) => {
    try {
        const { data } = await api.getTwoFactorInfoSecurity(email, token);
        if (!data.error) {
            callback(data);
        }
    } catch (error) {}
};
