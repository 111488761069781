import React, { useState, useEffect } from "react";
import { Autocomplete, Box, Button, Card, CardContent, CardHeader, Container, FormControlLabel, FormGroup, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from "@mui/material";
import { getCustomersByFilter } from "../actions/customer";
import OkDialog from "../components/Dialogs/OkDialog";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AdminLayout } from "./layouts/dashboard/adminlayout";
import { logout } from "../redux/customerSlice.js";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { sendEmails } from "../actions/admin.js";

const Page = (props) => {
    const token = useSelector((state) => state.customer.token);
    const dispatch = useDispatch();
    const [okDialog, setOkDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        isError: false,
    });
    const [emailData, setEmailData] = useState({
        subject: "",
        text: "",
    });
    const [filterCustomers, setFilterCustomers] = useState("All");
    const [customers, setCustomers] = useState([]);
    const [customersToEmail, setCustomersToEmail] = useState([]);
    const [additional, setAdditional] = useState([]);
    const [listClickDisable, setListClickDisable] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const navigate = useNavigate();

    const AddCustomerOrRemove = (customerCheck) => () => {
        const currentIndex = customersToEmail.indexOf(customerCheck);
        const newChecked = [...customersToEmail];

        console.log("TESTT CLICK", listClickDisable, customerCheck, currentIndex);

        if (currentIndex === -1) {
            console.log("METER");
            newChecked.push(customerCheck);
        } else {
            console.log("QUITAR");
            newChecked.splice(currentIndex, 1);
        }

        setCustomersToEmail(newChecked);
        console.log(customersToEmail);
    };

    const AddAllEmails = (e) => {
        e.preventDefault();
        setCustomersToEmail([]);
        setListClickDisable(true);
        let tempCusts = [];
        let tempCheck = [];
        for (let i = 0; i < customers.length; i++) {
            const cust = customers[i];
            tempCusts.push(cust);
            tempCheck.push(cust._id.toString());
        }

        setCheckedList(tempCheck);
        setCustomersToEmail(tempCusts);
        setListClickDisable(false);
    };

    const RemoveAllEmails = (e) => {
        e.preventDefault();
        setCustomersToEmail([]);
        setListClickDisable(true);
        setCheckedList([]);
        setListClickDisable(false);
    };

    const AddEspEmail = (e) => {
        e.preventDefault();
        const additionalTemp = [...additional];
        let emailText = document.getElementById("email").value;
        if (isValidEmail(emailText) && !additionalTemp.includes(emailText)) {
            additionalTemp.push(emailText);
            setAdditional(additionalTemp);
            document.getElementById("email").value = "";
            document.getElementById("email").focus();
        }
    };

    const RemoveEspEmail = (e) => {
        setAdditional([]);
    };

    function isValidEmail(email) {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            sendEmails(emailData, customersToEmail, additional, token, (data) => {
                console.log("RESULT DATA ", data);
                if (!data.error) {
                    setOkDialog({
                        isError: false,
                        isOpen: true,
                        title: "Send emails process.",
                        subTitle: data?.message,
                        onConfirm: () => {
                            setOkDialog({
                                ...okDialog,
                                isOpen: false,
                            });
                        },
                    });
                    navigate("/emails");
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        } else {
                            setOkDialog({
                                isError: true,
                                isOpen: true,
                                title: "Send emails process.",
                                subTitle: data?.message,
                                onConfirm: () => {
                                    setOkDialog({
                                        ...okDialog,
                                        isOpen: false,
                                    });
                                },
                            });
                        }
                    }
                }
            });
        } catch (error) {
            setOkDialog({
                isError: true,
                isOpen: true,
                title: "Send emails process.",
                subTitle: "Error sending emails, please contact support",
                onConfirm: () => {
                    setOkDialog({
                        ...okDialog,
                        isOpen: false,
                    });
                },
            });
        }
    };

    useEffect(() => {
        getCustomersByFilter(filterCustomers, token, (data) => {
            if (!data.error) {
                setCustomers(data.data.customers);
            } else {
                if (data.error) {
                    if (data.message === "Token Expire") {
                        dispatch(logout);
                        navigate("/");
                    }
                }
            }
        });
    }, []);

    return customers ? (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0,
                }}
            >
                <Container maxWidth="xl">
                    <Typography color="text.secondary" variant="overline" style={{ fontSize: "16px" }}>
                        {"Send Emails"}
                    </Typography>
                    <Grid container spacing={3} justifyContent="center">
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell width={"15%"}>{"Subject:"}</TableCell>
                                        <TableCell width={"85%"}>
                                            <TextField
                                                fullWidth
                                                label="Subject"
                                                name="subject"
                                                onChange={(e) => {
                                                    setEmailData({ ...emailData, subject: e.target.value });
                                                }}
                                                type="text"
                                                value={emailData.subject}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"15%"}>{"Text:"}</TableCell>
                                        <TableCell width={"85%"}>
                                            <TextField
                                                rows={6}
                                                fullWidth
                                                multiline
                                                label="Text"
                                                name="text"
                                                onChange={(e) => {
                                                    setEmailData({ ...emailData, text: e.target.value });
                                                }}
                                                type="text"
                                                defaultValue={emailData.text}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"100%"} colSpan={2}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell width={"50%"}>
                                                            <Typography color="text.secondary" variant="overline" style={{ fontSize: "16px", color: "black" }}>
                                                                {"Customers email list"}
                                                            </Typography>
                                                            <br />
                                                            <Button aria-label="all" onClick={AddAllEmails}>
                                                                <AddIcon />
                                                                Add All
                                                            </Button>
                                                            <Button aria-label="remove" onClick={RemoveAllEmails} sx={{ marginLeft: "5px" }}>
                                                                <ClearIcon />
                                                                Remove All
                                                            </Button>
                                                        </TableCell>
                                                        <TableCell width={"50%"}>
                                                            <Typography color="text.secondary" variant="overline" style={{ fontSize: "16px", color: "black" }}>
                                                                {"Additional Emails"}
                                                            </Typography>
                                                            <br />
                                                            <Button aria-label="allesp" onClick={AddEspEmail} sx={{ marginBottom: "10px" }}>
                                                                <AddIcon />
                                                                Add
                                                            </Button>
                                                            <Button aria-label="removeesp" onClick={RemoveEspEmail} sx={{ marginLeft: "5px", marginBottom: "10px" }}>
                                                                <ClearIcon />
                                                                Remove
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell width={"50%"}>
                                                            <Card sx={{ maxWidth: 360 }}>
                                                                <CardContent>
                                                                    <List id="allemails" dense sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper", position: "relative", overflow: "auto", maxHeight: 300 }}>
                                                                        {customers.map((cust) => {
                                                                            const labelId = `checkbox-list-secondary-label-${cust._id}`;
                                                                            return (
                                                                                <ListItem key={cust._id.toString()} secondaryAction={<Checkbox edge="end" id={cust._id.toString()} onChange={listClickDisable ? () => {} : AddCustomerOrRemove(cust)} checked={customersToEmail.includes(cust)} inputProps={{ "aria-labelledby": labelId }} />}>
                                                                                    <ListItemText primary={cust.name} />
                                                                                </ListItem>
                                                                            );
                                                                        })}
                                                                    </List>
                                                                </CardContent>
                                                            </Card>
                                                        </TableCell>
                                                        <TableCell width={"50%"} sx={{ alignContent: "baseline" }}>
                                                            <Card sx={{ maxWidth: 360, alignContent: "start", alignItems: "start" }}>
                                                                <CardContent>
                                                                    <TextField fullWidth label="Email" id="email" name="email" type="text" />
                                                                    <List dense sx={{ width: "100%", maxWidth: 360 }} id={"especific"}>
                                                                        {additional.map((email) => {
                                                                            const labelId = `checkbox-list-secondary-label-${email}`;
                                                                            return (
                                                                                <ListItem key={email.toString()}>
                                                                                    <ListItemText primary={email} />
                                                                                </ListItem>
                                                                            );
                                                                        })}
                                                                    </List>
                                                                </CardContent>
                                                            </Card>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <form noValidate onSubmit={handleSubmit}>
                                                <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
                                                    {"Send"}
                                                </Button>
                                            </form>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
                </Container>
            </Box>
        </>
    ) : null;
};

Page.getLayout = (page) => <AdminLayout>{page}</AdminLayout>;
export default Page;
