import { Box, Button, Card, Container, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import OkDialog from "../../components/Dialogs/OkDialog.js";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { logout } from "../../redux/customerSlice.js";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { updatePassword } from "../../actions/customer.js";
import { CustomerLayout } from "../layouts/dashboard/customerlayout.js";

const useStyles = makeStyles((theme) => ({}));

const Page = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPasswordOld, setShowPasswordOld] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errorConfirm, setErrorConfirm] = useState(false);
    const [okDialog, setOkDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        isError: false,
    });
    const token = useSelector((state) => state.customer.token);
    const email = useSelector((state) => state.customer.email);
    const [postData, setPostData] = useState({
        email: email,
        oldpassword: "",
        password: "",
        confirmPassword: "",
    });

    const handleShowPasswordOld = () => {
        setShowPasswordOld((prevShowPassword) => !prevShowPassword);
    };
    const handleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const handleShowConfirmPassword = () => {
        setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
    };

    const validate = () => {
        let returnValidate = true;
        const password = document.getElementById("password").value;
        const confirm = document.getElementById("confirm").value;
        if (password === confirm) {
            setErrorConfirm(false);
        } else {
            setErrorConfirm(true);
            returnValidate = false;
        }
        return returnValidate;
    };

    const submitPassword = (e) => {
        e.preventDefault();
        if (validate() === true) {
            e.currentTarget.disabled = true;
            updatePassword(postData, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch(logout);
                        navigate("/");
                    }
                }
                if (!res.error) {
                    setPostData({
                        ...postData,
                        oldpassword: "",
                        password: "",
                        confirmPassword: "",
                    });
                    document.getElementById("password").textContent = "";
                    document.getElementById("confirm").textContent = "";
                    document.getElementById("oldpassword").textContent = "";
                    setOkDialog({
                        isOpen: true,
                        title: "Settings Updated",
                        isError: false,
                        subTitle: res?.message,
                        onConfirm: () => {
                            setOkDialog({
                                ...okDialog,
                                isOpen: false,
                            });
                        },
                    });
                } else {
                    setOkDialog({
                        isOpen: true,
                        title: "Error on update",
                        isError: true,
                        subTitle: res?.message,
                        onConfirm: () => {
                            setOkDialog({
                                ...okDialog,
                                isOpen: false,
                            });
                        },
                    });
                }
            });
        } else {
            setOkDialog({
                isOpen: true,

                title: "Error on change password",
                subTitle: "Confirmation password does not match",
                isError: true,
                onConfirm: () => {
                    setOkDialog({
                        ...okDialog,
                        isOpen: false,
                    });
                },
            });
        }
    };

    let isMobile = false;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true;
    }

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 0,
            }}
        >
            <Container maxWidth="xl">
                <Typography color="text.secondary" variant="overline" style={{ fontSize: "16px" }}>
                    {"Change Password"}
                </Typography>
                <Grid container spacing={3} mt={0} justifyContent="center">
                    <Grid item xs={12} sm={12} lg={12} mt={0} key={"changepass"}>
                        <TextField
                            name="oldpassword"
                            id="oldpassword"
                            className={classes.input}
                            variant="outlined"
                            label="Old Password"
                            fullWidth
                            value={postData.oldpassword}
                            onChange={(e) =>
                                setPostData({
                                    ...postData,
                                    oldpassword: e.target.value,
                                })
                            }
                            type={showPasswordOld ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPasswordOld}>{showPasswordOld === true ? <Visibility /> : <VisibilityOff />}</IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <br />
                        <br />
                        <TextField
                            name="password"
                            id="password"
                            className={classes.input}
                            variant="outlined"
                            label="Password"
                            fullWidth
                            value={postData.password}
                            onChange={(e) =>
                                setPostData({
                                    ...postData,
                                    password: e.target.value,
                                })
                            }
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassword}>{showPassword === true ? <Visibility /> : <VisibilityOff />}</IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <br />
                        <br />
                        <TextField
                            name="confirm"
                            id="confirm"
                            className={classes.input}
                            variant="outlined"
                            label="Confirm Password"
                            fullWidth
                            value={postData.confirmPassword}
                            onChange={(e) =>
                                setPostData({
                                    ...postData,
                                    confirmPassword: e.target.value,
                                })
                            }
                            type={showConfirmPassword ? "text" : "password"}
                            {...(errorConfirm && {
                                error: true,
                                helperText: "Confirmation does not match with password",
                            })}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleShowConfirmPassword}>{showConfirmPassword === true ? <Visibility /> : <VisibilityOff />}</IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <br />
                        <br />
                        <Box textAlign="center">
                            <Button type="submit" fullWidth={isMobile ? true : false} variant="contained" className={classes.submit} onClick={submitPassword}>
                                UPDATE PASSWORD
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
            </Container>
        </Box>
    );
};

Page.getLayout = (page) => <CustomerLayout>{page}</CustomerLayout>;
export default Page;
