import PropTypes from "prop-types";
import { Avatar, Button, Card, CardContent, Stack, Typography } from "@mui/material";
import nflimage from "../assets/img/nfl.png";
import nbaimage from "../assets/img/nba.png";
import balls from "../assets/img/balls.png";
import ncaaimage from "../assets/img/ncaa.png";
import { useStyles } from "../assets/styles/components/global.js";
import { useState } from "react";

export const League = (props) => {
    const { sx, id, name, image, handleChangeLeague, setSelectButton, selectButton, keyInd } = props;
    const classes = useStyles();
    return (
        <Button
            fullWidth
            size="large"
            sx={{
                mt: 3,
            }}
            style={{ backgroundColor: selectButton === id ? "black" : "#FFCC33", color: selectButton === id ? "white" : "black" }}
            type="submit"
            variant="contained"
            onClick={() => {
                setSelectButton(id);
                handleChangeLeague(id);
            }}
            className={classes.submit}
        >
            {name.toString().toUpperCase()}
        </Button>
        //startIcon={<Avatar src={name === "NFL" ? nflimage : name === "NBA" ? nbaimage : name === "NCAA Football" ? ncaaimage : name === "All Leagues" ? balls : null} />}
    );
};

League.prototypes = {
    difference: PropTypes.number,
    positive: PropTypes.bool,
    sx: PropTypes.object,
    value: PropTypes.string.isRequired,
};

{
    /*<Card sx={sx}>
            <CardContent>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Typography color="text.secondary" variant="overline"></Typography>
                </Stack>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                        <img src={`/img/${image}`} alt="" width={"75px"} height={"75px"} />
                    </Stack>
                </Stack>
                <Stack alignItems="flex-end" direction="row" justifyContent="space-between" spacing={3}>
                    <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained" onClick={() => handleChangeLeague(id)} startIcon={<Avatar src={name === "NFL" ? nflimage : name === "NBA" ? nbaimage : name === "NCAA Football" ? ncaaimage : name === "All Leagues" ? balls : null} />}>
                        {name}
                    </Button>
                </Stack>
            </CardContent>
        </Card>*/
}
