import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AdminLayout } from "./views/layouts/dashboard/adminlayout.js";
import { CustomerLayout } from "./views/layouts/dashboard/customerlayout.js";
import Login from "./views/auth/login.js";
import SignUp from "./views/auth/register.js";
import { useSelector } from "react-redux";
import Packages from "./views/packages.js";
import PayMethod from "./views/payMethod.js";
import Picks from "./views/picks.js";
import AdminPicks from "./views/adminPicks.js";
import AdminPicksList from "./views/adminListPicks.js";
import AdminCustomersList from "./views/adminListCustomers.js";
import PaypalPaymentAccept from "./views/paypalPaymentAccept.js";
import PaypalPaymentCancel from "./views/paypalPaymentCancel.js";
import PayOrder from "./views/PayOrder.js";
import PayOrderCard from "./views/PayOrderCard/PayOrderCard.js";
import Catalogs from "./views/catalogs.js";
import AdminOrdersList from "./views/adminOrdersBuy.js";
import Marquees from "./views/marquee.js";
import ChangePassword from "./views/security/ChangePassword.js";
import Emails from "./views/adminMails.js";
import AdminCustomer from "./views/adminCustomer.js";

const AdminRoute = ({ isAdmin, children }) => {
    if (!isAdmin) {
        return <Navigate to="/" replace />;
    }
    return children;
};

const CustomerRoute = ({ isLogin, children }) => {
    console.log("Navigate to isLogin Customer Route", isLogin);
    if (!isLogin) {
        return <Navigate to="/" replace />;
    }
    return children;
};

function App(props) {
    const { pageProps } = props;
    const rutaServer = "";
    const isAdmin = useSelector((state) => state.customer.isAdmin);
    const isLogin = useSelector((state) => state.customer.isLogin);

    const getLayoutLogin = Login.getLayout ?? ((page) => page);
    const getLayoutEmails = Emails.getLayout ?? ((page) => page);
    const getLayoutSignUp = SignUp.getLayout ?? ((page) => page);
    const getPackagesPage = Packages.getLayout ?? ((page) => page);
    const getPayMethodPage = PayMethod.getLayout ?? ((page) => page);
    const getPicksPage = Picks.getLayout ?? ((page) => page);
    const getAdminPicksPage = AdminPicks.getLayout ?? ((page) => page);
    const getAdminCustomerPage = AdminCustomer.getLayout ?? ((page) => page);
    const getAdminListPicksPage = AdminPicksList.getLayout ?? ((page) => page);
    const getAdminListCustomersPage = AdminCustomersList.getLayout ?? ((page) => page);
    const getPaypalPaymentAccept = PaypalPaymentAccept.getLayout ?? ((page) => page);
    const getPaypalPaymentCancel = PaypalPaymentCancel.getLayout ?? ((page) => page);
    const getPayOIrder = PayOrder.getLayout ?? ((page) => page);
    const getPayOIrderCard = PayOrderCard.getLayout ?? ((page) => page);
    const getCatalogs = Catalogs.getLayout ?? ((page) => page);
    const getAdminListOrdersPage = AdminOrdersList.getLayout ?? ((page) => page);
    const getMarquees = Marquees.getLayout ?? ((page) => page);

    const getChangePassword = ChangePassword.getLayout ?? ((page) => page);

    return (
        <Routes>
            {/*LOGIN*/}
            <Route path={`${rutaServer}/`} element={getLayoutLogin(<Login {...pageProps} />)} />
            <Route path={`${rutaServer}/signup`} element={getLayoutSignUp(<SignUp {...pageProps} />)} />

            {/*ADMIN ROUTES*/}
            <Route
                path={`${rutaServer}/admin`}
                element={
                    <AdminRoute isAdmin={isAdmin}>
                        <AdminLayout />
                    </AdminRoute>
                }
            />
            <Route path={`${rutaServer}/adminpicks`} element={<AdminRoute isAdmin={isAdmin}>{getAdminPicksPage(<AdminPicks {...pageProps} />)}</AdminRoute>} />
            <Route path={`${rutaServer}/admincustomer`} element={<AdminRoute isAdmin={isAdmin}>{getAdminCustomerPage(<AdminCustomer {...pageProps} />)}</AdminRoute>} />
            <Route path={`${rutaServer}/adminpickslist`} element={<AdminRoute isAdmin={isAdmin}>{getAdminListPicksPage(<AdminPicksList {...pageProps} />)}</AdminRoute>} />
            <Route path={`${rutaServer}/admincustomerslist`} element={<AdminRoute isAdmin={isAdmin}>{getAdminListCustomersPage(<AdminCustomersList {...pageProps} />)}</AdminRoute>} />
            <Route path={`${rutaServer}/catalogs`} element={<AdminRoute isAdmin={isAdmin}>{getCatalogs(<Catalogs {...pageProps} />)}</AdminRoute>} />
            <Route path={`${rutaServer}/adminorderslist`} element={<AdminRoute isAdmin={isAdmin}>{getAdminListOrdersPage(<AdminOrdersList {...pageProps} />)}</AdminRoute>} />
            <Route path={`${rutaServer}/marquees`} element={<AdminRoute isAdmin={isAdmin}>{getMarquees(<Marquees {...pageProps} />)}</AdminRoute>} />
            <Route path={`${rutaServer}/emails`} element={<AdminRoute isAdmin={isAdmin}>{getLayoutEmails(<Emails {...pageProps} />)}</AdminRoute>} />

            {/*CUSTOMER*/}
            <Route
                path={`${rutaServer}/customer`}
                element={
                    <CustomerRoute isLogin={isLogin}>
                        <CustomerLayout />
                    </CustomerRoute>
                }
            />
            <Route path={`${rutaServer}/packages`} element={<CustomerRoute isLogin={isLogin}>{getPackagesPage(<Packages {...pageProps} />)}</CustomerRoute>} />
            <Route path={`${rutaServer}/paymethod`} element={<CustomerRoute isLogin={isLogin}>{getPayMethodPage(<PayMethod {...pageProps} />)}</CustomerRoute>} />
            <Route path={`${rutaServer}/picks`} element={<CustomerRoute isLogin={isLogin}>{getPicksPage(<Picks {...pageProps} />)}</CustomerRoute>} />
            <Route path={`${rutaServer}/cancelpaypalorder`} element={<CustomerRoute isLogin={isLogin}>{getPaypalPaymentCancel(<PaypalPaymentCancel {...pageProps} />)}</CustomerRoute>} />
            <Route path={`${rutaServer}/paymentpaypalaccept`} element={<CustomerRoute isLogin={isLogin}>{getPaypalPaymentAccept(<PaypalPaymentAccept {...pageProps} />)}</CustomerRoute>} />
            <Route path={`${rutaServer}/payrequestorder/:id`} element={<CustomerRoute isLogin={isLogin}>{getPayOIrder(<PayOrder {...pageProps} />)}</CustomerRoute>} />
            <Route path={`${rutaServer}/payrequestordercard/:id`} element={<CustomerRoute isLogin={isLogin}>{getPayOIrderCard(<PayOrderCard {...pageProps} />)}</CustomerRoute>} />
            <Route path={`${rutaServer}/changepassword`} element={<CustomerRoute isLogin={isLogin}>{getChangePassword(<ChangePassword {...pageProps} />)}</CustomerRoute>} />
        </Routes>
    );
}

export default App;
