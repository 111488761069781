import BarChartIcon from "@mui/icons-material/BarChart";
import PeopleIcon from "@mui/icons-material/People";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import InventoryIcon from "@mui/icons-material/Inventory";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ListIcon from "@mui/icons-material/List";
import MessageIcon from "@mui/icons-material/Message";
import { SvgIcon } from "@mui/material";

export const items = [
    {
        title: "Picks Admin",
        isAdmin: true,
        path: "/adminpicks",
        icon: (
            <SvgIcon fontSize="small">
                <SportsFootballIcon />
            </SvgIcon>
        ),
    },
    {
        title: "Picks List",
        isAdmin: true,
        path: "/adminpickslist",
        icon: (
            <SvgIcon fontSize="small">
                <FormatListNumberedIcon />
            </SvgIcon>
        ),
    },
    {
        title: "Catalogs",
        isAdmin: true,
        path: "/catalogs",
        icon: (
            <SvgIcon fontSize="small">
                <ListIcon />
            </SvgIcon>
        ),
    },
    {
        title: "Marquee",
        isAdmin: true,
        path: "/marquees",
        icon: (
            <SvgIcon fontSize="small">
                <MessageIcon />
            </SvgIcon>
        ),
    },
    {
        title: "Customers",
        isAdmin: true,
        path: "/admincustomerslist",
        icon: (
            <SvgIcon fontSize="small">
                <PeopleIcon />
            </SvgIcon>
        ),
    },
    {
        title: "Reports",
        isAdmin: true,
        path: "/adminorderslist",
        icon: (
            <SvgIcon fontSize="small">
                <BarChartIcon />
            </SvgIcon>
        ),
    },
    {
        title: "Emails",
        isAdmin: true,
        path: "/emails",
        icon: (
            <SvgIcon fontSize="small">
                <ListIcon />
            </SvgIcon>
        ),
    },
    {
        title: "Packages",
        isAdmin: false,
        path: "/packages",
        icon: (
            <SvgIcon fontSize="small">
                <InventoryIcon />
            </SvgIcon>
        ),
    },
    {
        title: "Picks",
        isAdmin: false,
        path: "/picks",
        icon: (
            <SvgIcon fontSize="small">
                <SportsFootballIcon />
            </SvgIcon>
        ),
    },
];
