import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Box, Button, Divider, Drawer, Stack, SvgIcon, Typography, useMediaQuery } from "@mui/material";
import { Logo } from "../../../components/logo.js";
import { Scrollbar } from "../../../components/scrollbar.js";
import { items } from "./config";
import { SideNavItem } from "./side-nav-item";
import imageLogo from "../../../assets/img/maingategroup_white.png";
import { useSelector } from "react-redux";

export const SideNav = (props) => {
    const { open, onClose } = props;
    const pathname = window.location.pathname;
    const isAdmin = useSelector((state) => state.customer.isAdmin);
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

    const content = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
            }}
        >
            <Box sx={{ p: 2 }}>
                <Box
                    sx={{
                        alignItems: "center",
                        //backgroundColor: "white", //"rgba(255, 255, 255, 0.04)",
                        borderRadius: 0,
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 2,
                        p: "0px",
                    }}
                >
                    <div>
                        <img src={imageLogo} alt="" width={"100%"} height={"100%"} />
                    </div>
                </Box>
            </Box>
            <Divider sx={{ borderColor: "neutral.000" }} />
            <Box
                component="nav"
                sx={{
                    flexGrow: 1,
                    px: 2,
                    py: 3,
                    height: "100%",
                }}
            >
                <Stack
                    component="ul"
                    spacing={0.5}
                    sx={{
                        listStyle: "none",
                        p: 0,
                        m: 0,
                    }}
                >
                    {items.map((item) => {
                        let active = item.path ? pathname === item.path : false;
                        if (item.isAdmin === isAdmin) {
                            return <SideNavItem active={active} disabled={item.disabled} external={true} icon={item.icon} key={item.title} path={item.path} title={item.title} />;
                        } else {
                            return null;
                        }
                    })}
                </Stack>
            </Box>
        </Box>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: "neutral.000",
                        color: "common.white",
                        width: 280,
                    },
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: "neutral.000",
                    color: "common.white",
                    width: 280,
                },
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

SideNav.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
};
