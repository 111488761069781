import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OkDialog from "./OkDialog.js";
import { logout } from "../../redux/customerSlice.js";
import { Button, Card, CardContent, CardHeader, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from "@mui/material";
import { getTwoFactorInfoSecurity, updateTwoFactorSecurity, verifyTwoFactCode } from "../../actions/security.js";

const useStyles = makeStyles((theme) => ({}));

const TwoFactor = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { okDialog, setOkDialog } = props;
    //const agentId = JSON.parse(localStorage.getItem('profile')).agentId;
    const email = useSelector((state) => state.customer.email);
    const token = useSelector((state) => state.customer.token);
    const [verify, setVerify] = useState(false);
    const [verifyCode, setVerifyCode] = useState(false);
    const [okDialogSmall, setOkDialogSmall] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });
    const [postData, setPostData] = useState({
        email: email,
        twoFactorAuth: false,
    });

    const [showOptions, setShowOptions] = useState({
        showQr: false,
        showVerify: false,
        qrAuthString: "",
    });

    const [pinData, setPinData] = useState({
        email: email,
        pin: "",
        fromLogin: false,
    });

    const handleChangePin = (e) => {
        e.preventDefault();
        setPinData({ ...pinData, [e.target.name]: e.target.value });
    };

    const handleOk = async (e) => {
        e.preventDefault();
        if (verify === true && verifyCode === false) {
            var info = {
                email: email,
                twoFactorAuth: false,
            };
            dispatch(
                updateTwoFactorSecurity(info, token, (res) => {
                    okDialog.onConfirm();
                })
            );
        } else {
            okDialog.onConfirm();
        }
    };

    const handleSubmitPin = (e) => {
        e.preventDefault();
        let pinValue = document.getElementById("pin").value;
        dispatch(
            verifyTwoFactCode(
                {
                    email: email,
                    pin: pinValue,
                    fromLogin: false,
                },
                null,
                token,
                (res) => {
                    if (res.error) {
                        if (res.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                    //console.log('RETORNO DE VERIFICACION', res);
                    if (!res.error) {
                        setVerifyCode(true);
                        setOkDialogSmall({
                            isError: false,
                            isOpen: true,
                            title: "Security code verified",
                            subTitle: "Two factor verification is now active.",
                            onConfirm: () => {
                                setOkDialogSmall({
                                    ...okDialogSmall,
                                    isOpen: false,
                                });
                            },
                        });
                        setShowOptions({ ...showOptions, showVerify: false });
                        setPinData({ ...pinData, pin: "" });
                    } else {
                        setOkDialogSmall({
                            isError: true,
                            isOpen: true,
                            title: "Security code expire",
                            subTitle: `You'r code expire or is invalid, please try again.`,
                            onConfirm: () => {
                                setOkDialogSmall({
                                    ...okDialogSmall,
                                    isOpen: false,
                                });
                            },
                        });
                    }
                }
            ),
            []
        );
    };

    let googleLink = "";

    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        googleLink = "https://apps.apple.com/us/app/google-authenticator/id388497605";
    } else {
        googleLink = "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US";
    }

    const changeTwoFactor = (e) => {
        e.preventDefault();
        setPostData({ ...postData, twoFactorAuth: e.target.checked });
        var info = {
            email: email,
            twoFactorAuth: e.target.checked,
        };
        if (e.target.checked === true) {
            setVerify(true);
        }
        dispatch(
            updateTwoFactorSecurity(info, token, (res) => {
                if (res.error) {
                    if (res.message === "Token Expire") {
                        dispatch(logout);
                        navigate("/");
                    }
                }
                if (!res.error) {
                    if (res.data.twoFactorAuth === true) {
                        setShowOptions({
                            showQr: true,
                            showVerify: res.data.verify,
                            qrAuthString: res.data.strImageQR,
                        });
                    } else {
                        setShowOptions({
                            showQr: false,
                            showVerify: false,
                            qrAuthString: "",
                        });
                        setOkDialogSmall({
                            isError: false,
                            isOpen: true,
                            title: "Security Updated",
                            subTitle: res?.message,
                            onConfirm: () => {
                                setOkDialogSmall({
                                    ...okDialogSmall,
                                    isOpen: false,
                                });
                            },
                        });
                    }
                } else {
                    setOkDialog({
                        isError: true,
                        isOpen: true,
                        title: "Error on update security",
                        subTitle: res?.message,
                        onConfirm: () => {
                            setOkDialogSmall({
                                ...okDialogSmall,
                                isOpen: false,
                            });
                        },
                    });
                }
            }),
            []
        );
    };

    useEffect(() => {
        dispatch(
            getTwoFactorInfoSecurity(email, token, (data) => {
                if (data.error) {
                    if (data.message === "Token Expire") {
                        dispatch(logout);
                        navigate("/");
                    }
                }
                if (data) {
                    //console.log('DATA ON LOAD', data.data);
                    let showq = data.data.twoFactorAuth;
                    let showAct = true;
                    if (!data.data.twoFactorAuthActive) {
                        showAct = false;
                    }
                    setShowOptions({
                        showQr: showq,
                        showVerify: showAct,
                        qrAuthString: data.data.strImageQR,
                    });
                    setPostData({
                        ...postData,
                        twoFactorAuth: data.data.twoFactorAuth,
                    });
                }
            })
        );
    }, [dispatch]);

    return (
        <Dialog open={okDialog.isOpen} PaperProps={{ sx: { position: "fixed", top: 100, textAlign: "center" } }}>
            <DialogTitle>
                <Typography sx={{ textAlign: "center", fontSize: "14px" }} className={okDialog.isError ? classes.titleError : classes.titleNormal}>
                    {okDialog.title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Container maxWidth="xl">
                    <Grid item xs={12} sm={6} md={12} justifyContent="center">
                        <Card style={{ borderRadius: "15px" }}>
                            <CardHeader color="info" style={{ borderRadius: "15px" }}>
                                <Typography className={classes.agentName}>{"Security"}</Typography>
                            </CardHeader>
                            <CardContent>
                                <FormControlLabel control={<Checkbox checked={postData.twoFactorAuth || false} onChange={changeTwoFactor} name="twoFactorAuth" color="primary" />} label="Enable Two Factor Authentication using Google Authenticator" className={classes.checkTwoFactor} />

                                {showOptions.showQr && (
                                    <>
                                        <br />
                                        <Typography className={classes.textoScan}>{"Scan this with the "}</Typography>
                                        <a href={googleLink} style={{ textDecoration: "none" }}>
                                            <Typography className={classes.textoGoogle}>{"Google Authenticator App"}</Typography>
                                        </a>
                                        <img src={showOptions.qrAuthString} className={classes.qrcode} />
                                        <form
                                            className={classes.form}
                                            onSubmit={handleSubmitPin}
                                            style={{
                                                display: showOptions.showVerify ? "block" : "none",
                                                textAlign: "center",
                                            }}
                                        >
                                            <Grid container spacing={1} sx={{ display: "inline-block" }}>
                                                <TextField name="pin" id="pin" variant="outlined" label="Verification Code" type={"number"} onChange={handleChangePin} value={pinData.pin} />
                                            </Grid>
                                            <Button type="submit" variant="contained" className={classes.submit}>
                                                {"Verify"}
                                            </Button>
                                        </form>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                    <OkDialog okDialog={okDialogSmall} setOkDialog={setOkDialogSmall} />
                </Container>
            </DialogContent>
            <DialogActions sx={{ textAlign: "center" }}>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ textAlign: "center" }}>
                                    <Button variant="contained" onClick={handleOk}>
                                        Ok
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogActions>
        </Dialog>
    );
};

export default TwoFactor;
