import { CardHeader } from "@mui/material";
import { Link as RouterKink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, Link, Stack, TextField, Typography } from "@mui/material";
import { LayoutRegister as AuthLayout } from "../layouts/auth/layoutRegister.js";
import { signUpCustomer } from "../../actions/customer.js";
import { useDispatch } from "react-redux";
import { useStyles } from "../../assets/styles/components/global.js";

const Page = () => {
    const router = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const formik = useFormik({
        initialValues: {
            email: "",
            name: "",
            password: "",
            submit: null,
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
            name: Yup.string().max(255).required("Name is required"),
            password: Yup.string().max(255).required("Password is required"),
        }),
        onSubmit: async (values, helpers) => {
            try {
                dispatch(
                    signUpCustomer(values.name, values.password, values.email, (res) => {
                        if (res.error) {
                            helpers.setStatus({ success: false });
                            helpers.setErrors({ submit: res.message });
                            helpers.setSubmitting(false);
                        } else {
                            router("/");
                        }
                    })
                );
            } catch (err) {
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
            }
        },
    });

    return (
        <>
            <Box
                sx={{
                    backgroundColor: "background.paper",
                    flex: "1 1 auto",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        maxWidth: 550,
                        px: 3,
                        width: "100%",
                    }}
                >
                    <div>
                        <Stack spacing={1} sx={{ mb: 3 }}>
                            <Typography variant="h4">Register</Typography>
                            <Typography color="text.secondary" variant="body2">
                                Already have an account? &nbsp;
                                <Link
                                    component="button"
                                    underline="hover"
                                    variant="subtitle2"
                                    onClick={() => {
                                        router("/");
                                    }}
                                >
                                    Login
                                </Link>
                            </Typography>
                        </Stack>
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <Stack spacing={3}>
                                <TextField error={!!(formik.touched.name && formik.errors.name)} fullWidth helperText={formik.touched.name && formik.errors.name} label="Name" name="name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.name} />
                                <TextField error={!!(formik.touched.email && formik.errors.email)} fullWidth helperText={formik.touched.email && formik.errors.email} label="Email Address" name="email" onBlur={formik.handleBlur} onChange={formik.handleChange} type="email" value={formik.values.email} />
                                <TextField error={!!(formik.touched.password && formik.errors.password)} fullWidth helperText={formik.touched.password && formik.errors.password} label="Password" name="password" onBlur={formik.handleBlur} onChange={formik.handleChange} type="password" value={formik.values.password} />
                            </Stack>
                            {formik.errors.submit && (
                                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                                    {formik.errors.submit}
                                </Typography>
                            )}
                            <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" className={classes.submit}>
                                Continue
                            </Button>
                        </form>
                    </div>
                </Box>
            </Box>
        </>
    );
};

Page.getLayout = (page) => <AuthLayout>{page}</AuthLayout>;

export default Page;
