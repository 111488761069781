import { Box, Button, CardHeader, Container, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CustomerLayout } from "./layouts/dashboard/customerlayout";
import { Package } from "../sections/package";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../actions/packages";
import { logout } from "../redux/customerSlice.js";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../assets/styles/components/global.js";

const Page = (props) => {
    const token = useSelector((state) => state.customer.token);
    const [packages, setPackages] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const email = useSelector((state) => state.customer.email);
    const classes = useStyles();
    const [hasPicks, setHasPicks] = useState(false);

    useEffect(() => {
        dispatch(
            getPackages(email, token, (data) => {
                console.log(data);
                if (!data.error) {
                    setPackages(data.data);
                    setHasPicks(data.hasPicks);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    const gotoPicks = (e) => {
        e.preventDefault();
        navigate("/picks");
    };

    let isMobile = false;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true;
    }

    return packages ? (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0,
                }}
            >
                <Container maxWidth="xl">
                    <Typography color="text.secondary" variant="overline" style={{ fontSize: "24px", color: "black", marginLeft: isMobile ? "25px" : "0px" }}>
                        {"Packages"}
                    </Typography>
                    {hasPicks == true ? (
                        <Grid container spacing={3} justifyContent="center">
                            <Button
                                size="large"
                                sx={{
                                    mt: 3,
                                    mb: 5,
                                }}
                                style={{ backgroundColor: "#FFCC33", color: "black" }}
                                type="submit"
                                variant="contained"
                                onClick={gotoPicks}
                                className={classes.submit}
                            >
                                {"GO TO PICKS"}
                            </Button>
                        </Grid>
                    ) : null}
                    <Grid container spacing={1} justifyContent="center">
                        {packages.map((pkg, key) => {
                            return (
                                <Grid item xs={12} sm={6} lg={3} mt={0} key={key}>
                                    <Package sx={{ height: "100%" }} packageName={pkg.name} imagesrc={pkg.image} description={pkg.description} price={pkg.price} id={pkg._id} prices={pkg.prices} />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Container>
            </Box>
        </>
    ) : null;
};

Page.getLayout = (page) => <CustomerLayout>{page}</CustomerLayout>;
export default Page;
