import * as api from "../api/catalogs.js";

export const getCatalogList = (catalogName, start, length, token, callback) => async (dispatch) => {
    const { data } = await api.getCatalogList(catalogName, start, length, token);
    callback(data);
};

export const updateCatalogItem = (catalogName, item, token, callback) => async (dispatch) => {
    const { data } = await api.updateCatalogItem(catalogName, item, token);
    callback(data);
};
