import axios from "axios";

//const API = axios.create({ baseURL: "http://localhost:5001", withCredentials: true });
const API = axios.create({ baseURL: "https://api.themaingategroup.com", withCredentials: true });

export const paymentAcceptProcess = (email, tokenOrder, payerId, method, type, token) => API.post("/customer/paypalorderaccept", { email, tokenOrder, payerId, method, type, token });

export const paymentCancelProcess = (tokenOrder, token) => API.post("/customer/paypalordercancel", { tokenOrder, token });

export const getPaypalClientId = (token) => API.post("/customer/getpaypalclientid", { token });
