import React, { useEffect, useState } from "react";
import { CustomerLayout } from "./layouts/dashboard/customerlayout";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { paymentAcceptProcess, paymentCancelProcess } from "../actions/paypal";
import { logout } from "../redux/customerSlice";
import { useNavigate } from "react-router-dom";

const Page = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const tokenOrder = searchParams.get("token");
    const token = useSelector((state) => state.customer.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(
            paymentCancelProcess(tokenOrder, token, (data) => {
                if (!data.error) {
                    navigate("/packages");
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    return <div>Payment cancel</div>;
};

Page.getLayout = (page) => <CustomerLayout>{page}</CustomerLayout>;
export default Page;
