import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider, MenuItem, MenuList, Popover, Typography } from "@mui/material";
import { logout } from "../../../redux/customerSlice";
import { useSelector, useDispatch } from "react-redux";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ListItemIcon from "@mui/material/ListItemIcon";
import { updateTwoFactorValue } from "../../../actions/customer";
import OkDialog from "../../../components/Dialogs/TwoFactor.js";

export const AccountPopover = (props) => {
    const { anchorEl, onClose, open } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const name = useSelector((state) => state.customer.name);
    const email = useSelector((state) => state.customer.email);
    const token = useSelector((state) => state.customer.token);
    const twoFactor = useSelector((state) => state.customer.twoFactorAuthActive);
    const [okDialog, setOkDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });

    const handleSignOut = useCallback(() => {
        onClose?.();
        logout();
        navigate("/");
    }, [onClose]);

    const handleChangePassword = useCallback(() => {
        navigate("/changepassword");
    }, []);

    const handleTwoFactorAuthentication = () => {
        setOkDialog({
            isError: true,
            isOpen: true,
            title: "Two Factor Authentication",
            subTitle: "",
            onConfirm: () => {
                setOkDialog({
                    ...okDialog,
                    isOpen: false,
                });
            },
        });
        /*dispatch(
            updateTwoFactorValue(email, token, (data) => {
                console.log(data);
                if (!data.error) {
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );*/
    };

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: "left",
                vertical: "bottom",
            }}
            onClose={onClose}
            open={open}
            PaperProps={{ sx: { width: 250 } }}
        >
            <Box
                sx={{
                    py: 1.5,
                    px: 2,
                }}
            >
                <Typography variant="overline">Account</Typography>
                <Typography color="text.secondary" variant="body2">
                    {name}
                </Typography>
            </Box>
            <Divider />
            <MenuList
                disablePadding
                dense
                sx={{
                    p: "8px",
                    "& > *": {
                        borderRadius: 1,
                    },
                }}
            >
                <MenuItem onClick={handleTwoFactorAuthentication}>
                    {twoFactor === true ? (
                        <ListItemIcon>
                            <CheckBoxIcon color="success" />
                        </ListItemIcon>
                    ) : (
                        <ListItemIcon>
                            <CheckBoxOutlineBlankIcon color="success" />
                        </ListItemIcon>
                    )}
                    Two Factor Authentication
                </MenuItem>
                <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
                <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
            </MenuList>
            <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
        </Popover>
    );
};

AccountPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
};
