import { useCallback, useEffect, useState } from "react";
import { CardHeader, Container, Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Alert, Box, Button, FormHelperText, Link, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Layout as AuthLayout } from "../layouts/auth/layout";
import { validateLogin } from "../../actions/customer.js";
import { useDispatch } from "react-redux";
import { useStyles } from "../../assets/styles/components/global.js";
import { verifyTwoFactCode } from "../../actions/security";
import { getPackagesNoToken } from "../../actions/packages";
import { PackageIndex } from "../../sections/packageIndex.js";

const LoginPackages = () => {
    const [packages, setPackages] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getPackagesNoToken((data) => {
                //console.log(data);
                if (!data.error) {
                    setPackages(data.data);
                }
            })
        );
    }, []);

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0,
                }}
            >
                <Container maxWidth="xl">
                    <Grid container spacing={3} justifyContent="center">
                        {packages.map((pkg, key) => {
                            return pkg ? (
                                <Grid item xs={12} sm={6} lg={3} mt={0} key={key}>
                                    <PackageIndex sx={{ height: "100%" }} packageName={""} imagesrc={pkg.image} bioTitle={pkg.bioTitle} bio={pkg.bio} price={pkg.price} id={pkg._id} />
                                </Grid>
                            ) : null;
                        })}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default LoginPackages;
