import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Card, CardContent, Box, Container, Typography, Grid, IconButton, TextField, FormControlLabel, Checkbox, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/customerSlice.js";
import { getCatalogList, updateCatalogItem } from "../../actions/catalogs.js";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme) => ({
    tableResponsive: {
        width: "100%",
        marginTop: "0px",
        overflowX: "auto",
        overflow: "auto",
    },
    tableHeadCell: {
        color: "inherit",
        "&, &$tableCell": {
            fontSize: "16px",
            fontWeight: "bold",
        },
    },
    tableHeadRow: {
        height: "36px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle",
    },
    tableCell: {
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "14px",
    },
    tableRow: {
        position: "relative",
        borderBottom: "1px solid #9dc09d",
    },
}));

const League = () => {
    const token = useSelector((state) => state.customer.token);
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [catalog, setCatalog] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [length, setLength] = useState(25);
    const catalogName = "Leagues";
    let start = 0;
    const [leagueInfo, setLeagueInfo] = useState({
        id: "",
        name: "",
        image: "",
        active: true,
    });

    let tableHead = ["Name", "Image", "Active", "Created"];

    const handleChangePage = (event, newPage) => {
        start = length * newPage;
        dispatch(
            getCatalogList(catalogName, start, length, token, (data) => {
                //console.log("TIENE DATA", data.error);
                if (!data.error) {
                    //console.log("SET DATA");
                    setCatalog(data.data.catalogs);
                    setRecordsTotal(data.data.total);
                } else {
                    if (data.error) {
                        //console.log("TOKEN MURIO");
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLength(parseInt(event.target.value, length));
        setPage(0);
    };

    const onToggleEditMode = (id) => {
        let item = catalog.find((i) => i._id.toString() === id.toString());
        setLeagueInfo({
            id: item._id.toString(),
            name: item.name,
            image: item.image,
            active: item.active,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(
            updateCatalogItem(catalogName, leagueInfo, token, (data) => {
                if (!data.error) {
                    //console.log("SET DATA", data);
                    setCatalog(data.data.catalogs);
                    setRecordsTotal(data.data.total);
                    setLeagueInfo({
                        id: "",
                        name: "",
                        image: "",
                        active: true,
                    });
                } else {
                    if (data.error) {
                        //console.log("TOKEN MURIO");
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    };

    const onCheckedActive = (e) => {
        e.preventDefault();
        setLeagueInfo({ ...leagueInfo, active: e.target.checked });
    };

    useEffect(() => {
        dispatch(
            getCatalogList(catalogName, start, length, token, (data) => {
                //console.log("TIENE DATA", data.error);
                if (!data.error) {
                    //console.log("SET DATA", data);
                    setCatalog(data.data.catalogs);
                    setRecordsTotal(data.data.total);
                } else {
                    if (data.error) {
                        //console.log("TOKEN MURIO");
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    return (
        <>
            <Card>
                <CardContent>
                    <Typography color="text.secondary" variant="overline" style={{ fontSize: "24px", color: "black" }}>
                        {"Leagues"}
                    </Typography>
                    <Grid container spacing={1} direction="row">
                        <TableContainer>
                            <Table className={classes.tableResponsive}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell width={"100%"}>
                                            <TextField
                                                fullWidth
                                                label="Name"
                                                name="name"
                                                onChange={(e) => {
                                                    setLeagueInfo({ ...leagueInfo, name: e.target.value });
                                                }}
                                                type="text"
                                                value={leagueInfo.name}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"100%"}>
                                            <FormControlLabel control={<Checkbox id={"active"} onChange={onCheckedActive} key={"active"} checked={leagueInfo.active} />} label={"Active"} key={"ClActive"} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"100%"}>
                                            <TextField
                                                fullWidth
                                                label="Image"
                                                name="image"
                                                onChange={(e) => {
                                                    setLeagueInfo({ ...leagueInfo, image: e.target.value });
                                                }}
                                                type="text"
                                                value={leagueInfo.image}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <form noValidate onSubmit={handleSubmit}>
                                                <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
                                                    {leagueInfo.id.length > 0 ? "Update" : "Save"}
                                                </Button>
                                            </form>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table className={classes.tableResponsive}>
                                <TableHead>
                                    <TableRow className={classes.tableHeadRow}>
                                        <TableCell align="left" className={classes.tableHeadRow} />
                                        {tableHead.map((name, key) => {
                                            return (
                                                <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                                                    {name.toString()}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {catalog.map((row) => (
                                        <TableRow key={row._id} className={classes.tableRow}>
                                            <TableCell className={classes.selectTableCell}>
                                                <IconButton aria-label="delete" onClick={() => onToggleEditMode(row._id)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>{row.name}</TableCell>
                                            <TableCell className={classes.tableCell}>{row.image}</TableCell>
                                            <TableCell className={classes.tableCell}>{row.active.toString()}</TableCell>
                                            <TableCell className={classes.tableCell}>{moment(row.createdAt).format("MM/DD/YYYY hh:mm a")}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination component="div" count={recordsTotal} page={page} onPageChange={handleChangePage} rowsPerPage={length} onRowsPerPageChange={handleChangeRowsPerPage} />
                        </TableContainer>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default League;
