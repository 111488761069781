import { Box, CardHeader, Container, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CustomerLayout } from "./layouts/dashboard/customerlayout";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/customerSlice.js";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getPayOrderRequest } from "../actions/paymentMethods";

const Page = (props) => {
    const token = useSelector((state) => state.customer.token);
    //let [searchParams, setSearchParams] = useSearchParams();
    const [requestOrder, setRequestOrder] = useState(null);
    let { id } = useParams();

    //console.log("parametro", id);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(
            getPayOrderRequest(id, token, (data) => {
                if (!data.error) {
                    setRequestOrder(data.data);
                    window.open(data.data.link, "_top", "noopener,noreferrer");
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    if (requestOrder) {
        if (requestOrder.method === "Paypal") {
            return (
                <>
                    {/*<Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            py: 0,
                        }}
                    >
                        <Container maxWidth="xl">
                            <Grid container spacing={3} justifyContent="center">
                                <iframe src={requestOrder.link} frameBorder="0" height="1000px" width="100%" scrolling="no" />
                            </Grid>
                        </Container>
                    </Box>*/}
                </>
            );
        }
    }
};

Page.getLayout = (page) => <CustomerLayout>{page}</CustomerLayout>;
export default Page;
