import { Autocomplete, Box, Button, CardHeader, Checkbox, Container, FormControlLabel, FormGroup, Grid, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { AdminLayout } from "./layouts/dashboard/adminlayout";
import { Package } from "../sections/package";
import { useDispatch, useSelector } from "react-redux";
import { addCustomerPackage, addUpdatePick, getCustomerInfo, getPicksAddInfo, updateCustomer, updateCustomerPackage } from "../actions/customer.js";
import { logout } from "../redux/customerSlice.js";
import { useNavigate } from "react-router-dom";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import OkDialog from "../components/Dialogs/OkDialog";
import { useSearchParams } from "react-router-dom";

const Page = (props) => {
    let [searchParams, setSearchParams] = useSearchParams();
    let id = "";
    if (searchParams.get("id")) {
        id = searchParams.get("id");
    }
    const token = useSelector((state) => state.customer.token);
    const [packages, setPackages] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [customerPackage, setCustomerPackage] = useState(null);
    const [optionsPrice, setOptionsPrice] = useState([]);
    const [okDialog, setOkDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        isError: false,
    });

    const [addPackage, setAddPackage] = useState({
        customer: id,
        package: "",
        active: true,
        paymentMethod: "",
        price: 0,
        endDate: Date.now(),
        type: -1,
    });

    let optionsPay = [
        { value: "E-Check", name: "E-Check" },
        { value: "Card", name: "Card" },
        { value: "Paypal", name: "Paypal" },
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(
            getCustomerInfo(id, token, (data) => {
                console.log(data);
                if (!data.error) {
                    setPackages(data.data.packages);
                    setCustomer(data.data.customer);
                    setCustomerPackage(data.data.customerPackage);
                    //console.log("Leagues", data.data.leagues);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        //console.log("Info to save", pickInfo);
        updateCustomer(customer, token, (data) => {
            if (!data.error) {
                setOkDialog({
                    isError: false,
                    isOpen: true,
                    title: "Update Customer result",
                    subTitle: data?.message,
                    onConfirm: () => {
                        setOkDialog({
                            ...okDialog,
                            isOpen: false,
                        });
                    },
                });
                navigate("/admincustomerslist");
            } else {
                if (data.error) {
                    if (data.message === "Token Expire") {
                        dispatch(logout);
                        navigate("/");
                    } else {
                        setOkDialog({
                            isError: true,
                            isOpen: true,
                            title: "Update Customer result",
                            subTitle: data?.message,
                            onConfirm: () => {
                                setOkDialog({
                                    ...okDialog,
                                    isOpen: false,
                                });
                            },
                        });
                    }
                }
            }
        });
    };

    const handleSubmitUpdatePackage = (e) => {
        e.preventDefault();
        console.log("Update Package", customerPackage);
        updateCustomerPackage(customerPackage, token, (data) => {
            if (!data.error) {
                setOkDialog({
                    isError: false,
                    isOpen: true,
                    title: "Update Customer result",
                    subTitle: data?.message,
                    onConfirm: () => {
                        setOkDialog({
                            ...okDialog,
                            isOpen: false,
                        });
                    },
                });
                navigate("/admincustomerslist");
            } else {
                if (data.error) {
                    if (data.message === "Token Expire") {
                        dispatch(logout);
                        navigate("/");
                    } else {
                        setOkDialog({
                            isError: true,
                            isOpen: true,
                            title: "Update Customer result",
                            subTitle: data?.message,
                            onConfirm: () => {
                                setOkDialog({
                                    ...okDialog,
                                    isOpen: false,
                                });
                            },
                        });
                    }
                }
            }
        });
    };

    const handleSubmitAddPackage = (e) => {
        e.preventDefault();
        //console.log("Info to save", pickInfo);
        addCustomerPackage(addPackage, token, (data) => {
            if (!data.error) {
                setOkDialog({
                    isError: false,
                    isOpen: true,
                    title: "Update Customer result",
                    subTitle: data?.message,
                    onConfirm: () => {
                        setOkDialog({
                            ...okDialog,
                            isOpen: false,
                        });
                    },
                });
                navigate("/admincustomerslist");
            } else {
                if (data.error) {
                    if (data.message === "Token Expire") {
                        dispatch(logout);
                        navigate("/");
                    } else {
                        setOkDialog({
                            isError: true,
                            isOpen: true,
                            title: "Update Customer result",
                            subTitle: data?.message,
                            onConfirm: () => {
                                setOkDialog({
                                    ...okDialog,
                                    isOpen: false,
                                });
                            },
                        });
                    }
                }
            }
        });
    };

    const onCheckedActive = (e) => {
        e.preventDefault();
        setCustomer({ ...customer, active: e.target.checked });
    };

    const onCheckedActiveCustPackage = (e) => {
        e.preventDefault();
        setCustomerPackage({ ...customerPackage, active: e.target.checked });
    };

    const onExpitrationChange = (newValue) => {
        //console.log(dayjs(newValue).format("YYYY-MM-DD HH:mm:ss"));
        setCustomerPackage({ ...customerPackage, endDate: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") });
    };

    const onExpitrationAddChange = (newValue) => {
        //console.log(dayjs(newValue).format("YYYY-MM-DD HH:mm:ss"));
        setAddPackage({ ...addPackage, endDate: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") });
    };

    const onCheckedPackage = (e) => {
        e.preventDefault();
        setCustomerPackage({ ...customerPackage, package: e.target.id });
    };

    const onChangeCustomerPackage = (e, value) => {
        e.preventDefault();
        setCustomerPackage({ ...customerPackage, package: value._id.toString() });
    };
    const onChangeCustomerAddPackage = (e, value) => {
        e.preventDefault();
        setAddPackage({ ...addPackage, package: value._id.toString() });
        let options = [];
        value.packagePrice.forEach((element) => {
            console.log(element);
            var opt = {
                type: element.packageType.type,
                name: element.packageType.name,
                price: element.price,
            };
            options.push(opt);
        });

        setOptionsPrice(options);
    };

    const onChangeCustomerAddPackagePay = (e, value) => {
        e.preventDefault();
        setAddPackage({ ...addPackage, paymentMethod: value.value.toString() });
    };

    const onChangeCustomerAddPackageType = (e, value) => {
        e.preventDefault();
        setAddPackage({ ...addPackage, type: value.type, price: parseFloat(value.price) });
        console.log(addPackage);
    };

    return customer ? (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0,
                }}
            >
                <Container maxWidth="xl">
                    <Typography color="text.secondary" variant="overline" style={{ fontSize: "16px" }}>
                        {"Customer Info"}
                    </Typography>
                    <Grid container spacing={3} justifyContent="center">
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell width={"15%"}>{"Name:"}</TableCell>
                                        <TableCell width={"85%"}>{customer.name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"15%"}>{"Password:"}</TableCell>
                                        <TableCell width={"85%"}>{customer.password}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"15%"}>{"Email:"}</TableCell>
                                        <TableCell width={"85%"}>{customer.email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"15%"}>{"Active:"}</TableCell>
                                        <TableCell width={"85%"}>
                                            <FormControlLabel control={<Checkbox id={"active"} onChange={onCheckedActive} key={"keyactivecust"} defaultChecked={customer.active} />} label={"Active"} key={"lblactive"} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Button fullWidth size="large" sx={{ mt: 3 }} variant="contained" onClick={handleSubmit}>
                                                {"Update Customer"}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    {customerPackage ? (
                                        <>
                                            <TableRow>
                                                <TableCell width={"100%"} colSpan={2}>
                                                    <Typography color="text.secondary" variant="overline" style={{ fontSize: "16px" }}>
                                                        {"Actual customer package"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={"15%"}>{"Package:"}</TableCell>
                                                <TableCell width={"85%"}>
                                                    <Autocomplete value={packages.find((pk) => pk._id.toString() == customerPackage.package.toString())} id="package" style={{ width: 250 }} options={packages} getOptionLabel={(option) => option.name} renderInput={(params) => <TextField {...params} label="Package" margin="normal" />} onChange={onChangeCustomerPackage} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={"15%"}>{"Active:"}</TableCell>
                                                <TableCell width={"85%"}>
                                                    <FormControlLabel control={<Checkbox id={"active"} onChange={onCheckedActiveCustPackage} key={"keyactive"} defaultChecked={customerPackage.active} />} label={"Active"} key={"lblactive"} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={"15%"}>{"Expiration Date:"}</TableCell>
                                                <TableCell width={"85%"}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <MobileDatePicker label="Expiration" inputFormat="MM/DD/YYYY" value={dayjs(customerPackage.endDate)} onChange={onExpitrationChange} renderInput={(params) => <TextField {...params} />} />
                                                    </LocalizationProvider>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <Button fullWidth size="large" sx={{ mt: 3 }} variant="contained" onClick={handleSubmitUpdatePackage}>
                                                        {"Update Package"}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ) : (
                                        <>
                                            <TableRow>
                                                <TableCell width={"100%"} colSpan={2}>
                                                    <Typography color="text.secondary" variant="overline" style={{ fontSize: "16px" }}>
                                                        {"Add customer package"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={"15%"}>{"Package:"}</TableCell>
                                                <TableCell width={"85%"}>
                                                    <Autocomplete id="package" style={{ width: 250 }} options={packages} getOptionLabel={(option) => option.name} renderInput={(params) => <TextField {...params} label="Package" margin="normal" />} onChange={onChangeCustomerAddPackage} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={"15%"}>{"Pay Method:"}</TableCell>
                                                <TableCell width={"85%"}>
                                                    <Autocomplete id="paymethod" style={{ width: 250 }} options={optionsPay} getOptionLabel={(option) => option.name} renderInput={(params) => <TextField {...params} label="Pay Method" margin="normal" />} onChange={onChangeCustomerAddPackagePay} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={"15%"}>{"Expiration Date:"}</TableCell>
                                                <TableCell width={"85%"}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <MobileDatePicker label="Expiration" inputFormat="MM/DD/YYYY" defaultValue={dayjs(Date.now())} onChange={onExpitrationAddChange} renderInput={(params) => <TextField {...params} />} />
                                                    </LocalizationProvider>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width={"15%"}>{"Package Type:"}</TableCell>
                                                <TableCell width={"85%"}>
                                                    <Autocomplete id="packagetype" style={{ width: 250 }} options={optionsPrice} getOptionLabel={(option) => option.name} renderInput={(params) => <TextField {...params} label="Package Type" margin="normal" />} onChange={onChangeCustomerAddPackageType} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <Button fullWidth size="large" sx={{ mt: 3 }} variant="contained" onClick={handleSubmitAddPackage}>
                                                        {"Add Customer Package"}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
                </Container>
            </Box>
        </>
    ) : null;
};

Page.getLayout = (page) => <AdminLayout>{page}</AdminLayout>;
export default Page;
