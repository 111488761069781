import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Card, CardContent, Typography, Grid, TextField, IconButton, FormControlLabel, Checkbox, Button, Slider, FormLabel, RadioGroup, Radio, FormControl } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/customerSlice.js";
import { getCatalogList, updateCatalogItem } from "../../actions/catalogs.js";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { getMarqueesList, updateMarqueeItem, updateMarqueeSettings } from "../../actions/marquees.js";
import OkDialog from "../Dialogs/OkDialog";
import ColorPicker from "mui-color-picker";

const useStyles = makeStyles((theme) => ({
    tableResponsive: {
        width: "100%",
        marginTop: "0px",
        overflowX: "auto",
        overflow: "auto",
    },
    tableHeadCell: {
        color: "inherit",
        "&, &$tableCell": {
            fontSize: "16px",
            fontWeight: "bold",
        },
    },
    tableHeadRow: {
        height: "36px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle",
    },
    tableCell: {
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "14px",
    },
    tableRow: {
        position: "relative",
        borderBottom: "1px solid #9dc09d",
    },
}));

const Marquee = () => {
    const token = useSelector((state) => state.customer.token);
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [marquees, setMarquees] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [length, setLength] = useState(25);
    const [okDialog, setOkDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        isError: false,
    });
    const catalogName = "Marquees";
    let start = 0;

    const marks = [
        {
            value: 0,
            label: "0%",
        },
        {
            value: 50,
            label: "50% Speed",
        },
        {
            value: 100,
            label: "100%",
        },
    ];

    const [marqueeInfo, setMarqueeInfo] = useState({
        id: "",
        text: "",
        img: "",
        imgPosition: "",
        active: true,
        size: 0,
        color: "green",
    });

    const [settings, setSettings] = useState({
        id: "",
        direction: "left",
        speed: 50,
        pauseOnOver: false,
    });

    let tableHead = ["Text", "Active"];

    const handleChangePage = (event, newPage) => {
        start = length * newPage;
        dispatch(
            getMarqueesList(start, length, token, (data) => {
                //console.log("TIENE DATA", data.error);
                if (!data.error) {
                    //console.log("SET DATA");
                    setMarquees(data.data.marquees);
                    setSettings({
                        id: data.data.settings._id,
                        direction: data.data.settings.direction,
                        speed: data.data.settings.speed,
                        pauseOnOver: data.data.settings.pauseOnOver,
                    });
                    setRecordsTotal(data.data.total);
                } else {
                    if (data.error) {
                        //console.log("TOKEN MURIO");
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLength(parseInt(event.target.value, length));
        setPage(0);
    };

    useEffect(() => {
        dispatch(
            getMarqueesList(start, length, token, (data) => {
                //console.log("TIENE DATA", data.error);
                if (!data.error) {
                    //console.log("SET DATA");
                    setMarquees(data.data.marquees);
                    setSettings({
                        id: data.data.settings._id,
                        direction: data.data.settings.direction,
                        speed: data.data.settings.speed,
                        pauseOnOver: data.data.settings.pauseOnOver,
                    });
                    setRecordsTotal(data.data.total);
                } else {
                    if (data.error) {
                        //console.log("TOKEN MURIO");
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    const onToggleEditMode = (id) => {
        let item = marquees.find((i) => i._id.toString() === id.toString());
        setMarqueeInfo({
            id: item._id.toString(),
            text: item.text,
            img: item.img,
            imgPosition: item.imgPosition,
            active: item.active,
            size: item.size,
            color: item.color,
        });
        document.getElementById("text").focus();
    };

    const onCheckedActive = (e) => {
        e.preventDefault();
        setMarqueeInfo({ ...marqueeInfo, active: e.target.checked });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(
            updateMarqueeItem(marqueeInfo, token, (data) => {
                if (!data.error) {
                    //console.log("SET DATA", data);
                    setMarquees(data.data.marquees);
                    setSettings({
                        id: data.data.settings._id,
                        direction: data.data.settings.direction,
                        speed: data.data.settings.speed,
                        pauseOnOver: data.data.settings.pauseOnOver,
                    });
                    setRecordsTotal(data.data.total);
                    setMarqueeInfo({
                        id: "",
                        text: "",
                        img: "",
                        imgPosition: "",
                        active: true,
                        size: 0,
                        color: "green",
                    });
                } else {
                    if (data.error) {
                        //console.log("TOKEN MURIO");
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    };

    const handleSubmitSettings = (e) => {
        console.log("CLICK ON UPDATE SETTINGS");
        e.preventDefault();
        updateMarqueeSettings(settings, token, (data) => {
            if (!data.error) {
                //console.log("SET DATA", data);
                setMarquees(data.data.marquees);
                setSettings({
                    id: data.data.settings._id,
                    direction: data.data.settings.direction,
                    speed: data.data.settings.speed,
                    pauseOnOver: data.data.settings.pauseOnOver,
                });
                setRecordsTotal(data.data.total);
                setMarqueeInfo({
                    id: "",
                    text: "",
                    img: "",
                    imgPosition: "",
                    active: true,
                    size: 0,
                    color: "green",
                });
                setOkDialog({
                    isError: false,
                    isOpen: true,
                    title: "Update Settings",
                    subTitle: "Settings updated",
                    onConfirm: () => {
                        setOkDialog({
                            ...okDialog,
                            isOpen: false,
                        });
                    },
                });
            } else {
                if (data.error) {
                    //console.log("TOKEN MURIO");
                    if (data.message === "Token Expire") {
                        dispatch(logout);
                        navigate("/");
                    }
                }
            }
        });
    };

    function valuetext(value) {
        return `${value}%`;
    }

    const onChangeDirection = (e) => {
        e.preventDefault();
        console.log("DIRECTION", e.target.value);
        setSettings({ ...settings, direction: e.target.value });
    };
    const onChangePause = (e) => {
        e.preventDefault();
        console.log("PAUSE", e.target.checked);
        setSettings({ ...settings, pauseOnOver: e.target.checked });
    };

    const changeValue = (event, value) => {
        event.preventDefault();
        console.log("SPEED", value);
        setSettings({ ...settings, speed: value });
    };

    return settings ? (
        <>
            <Card>
                <CardContent>
                    <Typography color="text.secondary" variant="overline" style={{ fontSize: "24px", color: "black" }}>
                        {"Marquees"}
                    </Typography>
                    <Grid container spacing={1} direction="row">
                        <TableContainer>
                            <Table className={classes.tableResponsive}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell width={"100%"}>
                                            <TextField
                                                rows={3}
                                                fullWidth
                                                multiline
                                                //helperText={"Pick description"}
                                                label="Text"
                                                name="text"
                                                id="text"
                                                onChange={(e) => {
                                                    setMarqueeInfo({ ...marqueeInfo, text: e.target.value });
                                                }}
                                                type="text"
                                                value={marqueeInfo.text}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"100%"}>
                                            <FormControlLabel control={<Checkbox id={"active"} onChange={onCheckedActive} key={"active"} checked={marqueeInfo.active} />} label={"Active"} key={"ClActive"} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"100%"}>
                                            <TextField
                                                fullWidth
                                                //helperText={"Pick description"}
                                                label="Text Size"
                                                name="size"
                                                id="size"
                                                onChange={(e) => {
                                                    setMarqueeInfo({ ...marqueeInfo, size: e.target.value });
                                                }}
                                                type="text"
                                                value={marqueeInfo.size || ""}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"100%"} sx={{ fontSize: marqueeInfo.size }}>
                                            <ColorPicker name="color" defaultValue="Color Text" value={marqueeInfo.color || "green"} onChange={(colornew) => setMarqueeInfo({ ...marqueeInfo, color: colornew })} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"100%"}>
                                            <div style={{ fontSize: (marqueeInfo?.size?.toString() + "px").toString() || "12px", fontWeight: "bold", color: marqueeInfo.color }}>Example Text</div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Button fullWidth size="large" sx={{ mt: 3 }} variant="contained" onClick={handleSubmit}>
                                                {marqueeInfo.id.length > 0 ? "Update" : "Save"}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography color="text.secondary" variant="overline" style={{ fontSize: "24px", color: "black" }}>
                                                {"Settings"}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Slider aria-label="Speed" min={0} max={100} value={settings.speed} valueLabelDisplay="auto" getAriaValueText={valuetext} step={1} marks={marks} onChange={changeValue} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <FormControl>
                                                <FormLabel id="demo-row-radio-buttons-group-label">Direction</FormLabel>
                                                <RadioGroup row aria-labelledby="direction" name="direction" value={settings.direction} onChange={onChangeDirection}>
                                                    <FormControlLabel value="left" control={<Radio />} label="Left" />
                                                    <FormControlLabel value="right" control={<Radio />} label="Right" />
                                                </RadioGroup>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <FormControlLabel control={<Checkbox id={"pauseOnOver"} onChange={onChangePause} key={"pauseOnOver"} checked={settings.pauseOnOver} />} label={"Pause on over"} key={"ClPauseOnOver"} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Button fullWidth size="large" sx={{ mt: 3 }} variant="contained" onClick={handleSubmitSettings}>
                                                {"Update Settings"}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table className={classes.tableResponsive}>
                                <TableHead>
                                    <TableRow className={classes.tableHeadRow}>
                                        <TableCell align="left" className={classes.tableHeadRow} />
                                        {tableHead.map((name, key) => {
                                            return (
                                                <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                                                    {name.toString()}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {marquees.map((row) => (
                                        <TableRow key={row._id} className={classes.tableRow}>
                                            <TableCell className={classes.selectTableCell}>
                                                <IconButton aria-label="delete" onClick={() => onToggleEditMode(row._id)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>{row.text}</TableCell>
                                            <TableCell className={classes.tableCell}>{row.active.toString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination component="div" count={recordsTotal} page={page} onPageChange={handleChangePage} rowsPerPage={length} onRowsPerPageChange={handleChangeRowsPerPage} />
                        </TableContainer>
                    </Grid>
                </CardContent>
                <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
            </Card>
        </>
    ) : null;
};

export default Marquee;
