import * as api from "../api/customer.js";
import { login, updateTwoFactor } from "../redux/customerSlice.js";

export const validateName = (name, callback) => async (dispatch) => {
    const { data } = await api.validateName(name);
    callback(data);
};

export const validateEmail = (email, callback) => async (dispatch) => {
    const { data } = await api.validateEmail(email);
    callback(data);
};

export const validateLogin = (email, password, callback) => async (dispatch) => {
    const { data } = await api.validateLogin(email, password);
    if (!data.error) {
        dispatch(login(data));
    }
    callback(data);
};

export const signUpCustomer = (name, password, email, callback) => async (dispatch) => {
    const { data } = await api.signUpCustomer(name, password, email);
    if (!data.error) {
        dispatch(login(data));
    }
    callback(data);
};

export const getCustomersList = (name, status, start, length, token, callback) => async (dispatch) => {
    const { data } = await api.getCustomersList(name, status, start, length, token);
    callback(data);
};

export const updateTwoFactorValue = (email, token, callback) => async (dispatch) => {
    const { data } = await api.updateTwoFactorValue(email, token);
    if (!data.error) {
        dispatch(updateTwoFactor(data));
    }
    callback(data);
};

export const updatePassword = async (postData, token, callback) => {
    const { data } = await api.updatePassword(postData, token);
    callback(data);
};

export const getCustomersByFilter = async (filterCustomers, token, callback) => {
    const { data } = await api.getCustomersByFilter(filterCustomers, token);
    callback(data);
};

export const getCustomerInfo = (id, token, callback) => async (dispatch) => {
    const { data } = await api.getCustomerInfo(id, token);
    callback(data);
};

export const updateCustomer = async (customer, token, callback) => {
    const { data } = await api.updateCustomer(customer, token);
    callback(data);
};
export const updateCustomerPackage = async (pkg, token, callback) => {
    const { data } = await api.updateCustomerPackage(pkg, token);
    callback(data);
};
export const addCustomerPackage = async (pkg, token, callback) => {
    const { data } = await api.addCustomerPackage(pkg, token);
    callback(data);
};
