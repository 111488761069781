import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPaypalClientId, paymentCancelProcess } from "../../actions/paypal.js";
import { logout } from "../../redux/customerSlice.js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { continuePaymentMethod } from "../../actions/paymentMethods.js";
import OkDialog from "../../components/Dialogs/OkDialog.js";

const PaypalCheckOut = (props) => {
    const { img, id, total, name, packageTypeName, packageType } = props;
    const [paypalClientId, setPaypalClientId] = useState("");
    const [orderID, setOrderID] = useState(false);
    const [success, setSuccess] = useState(false);
    const [okDialog, setOkDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        isError: false,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.customer.token);
    const email = useSelector((state) => state.customer.email);

    useEffect(() => {
        dispatch(
            getPaypalClientId(token, (data) => {
                if (!data.error) {
                    setPaypalClientId(data.data);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    // creates a paypal order
    const createOrder = (data, actions) => {
        return actions.order
            .create({
                intent: "CAPTURE",
                purchase_units: [
                    {
                        amount: {
                            currency_code: "USD",
                            value: total.toString(),
                        },
                        description: name.toString(),
                    },
                ],
                application_context: {
                    brand_name: "TheMainGateGroup",
                    user_action: "PAY_NOW",
                    shipping_preference: "NO_SHIPPING",
                    payment_method: {
                        payer_selected: "PAYPAL",
                        payee_preferred: "IMMEDIATE_PAYMENT_REQUIRED",
                    },
                },
            })
            .then((orderID) => {
                setOrderID(orderID);
                dispatch(
                    continuePaymentMethod(email, id, orderID, packageType, token, (datafrom) => {
                        if (!datafrom.error) {
                        } else {
                            if (datafrom.error) {
                                if (datafrom.message === "Token Expire") {
                                    dispatch(logout);
                                    navigate("/");
                                }
                            }
                        }
                    })
                );
                return orderID;
            });
    };
    //console.log("typeeeeeeeeeee", packageType);
    // check Approval
    const onApprove = (data, actions) => {
        //console.log("APPROVE", data);
        navigate(`/paymentpaypalaccept?token=${data.orderID}&PayerID=${data.payerID}&method=${data.paymentSource}&type=${packageType}`);
    };

    const onCancel = (data) => {
        //console.log("CANCEL", data.orderID);
        dispatch(
            paymentCancelProcess(data.orderID, token, (data) => {
                if (!data.error) {
                    //navigate("/packages");
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    };

    //capture likely error
    const onError = (data, actions) => {
        setOkDialog({
            isError: true,
            isOpen: true,
            title: "Paypal payment error.",
            subTitle: "An Error occured with your payment.",
            onConfirm: () => {
                setOkDialog({
                    ...okDialog,
                    isOpen: false,
                });
            },
        });
    };

    return paypalClientId ? (
        <>
            <PayPalScriptProvider options={{ "client-id": paypalClientId }}>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Typography color="text.secondary" variant="overline">
                        Order Summary
                    </Typography>
                </Stack>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                        <img src={`/img/${img}`} alt="" width={"98%"} height={"98%"} />
                    </Stack>
                </Stack>
                <Stack alignItems="flex-end" direction="row" justifyContent="space-between" spacing={3}>
                    <Typography color="text.secondary" variant="overline" sx={{ fontSize: "26px", fontWeight: "bold", color: "black" }}>
                        {`Package Type: ${packageTypeName}`}
                    </Typography>
                </Stack>
                <Stack alignItems="flex-end" direction="row" justifyContent="space-between" spacing={3}>
                    <Typography color="text.secondary" variant="overline" sx={{ fontSize: "24px", fontWeight: "bold", color: "black" }}>
                        {`Total: $${total}`}
                    </Typography>
                </Stack>
                <div style={{ width: "100%" }}>
                    <PayPalButtons createOrder={createOrder} onApprove={onApprove} onError={onError} onCancel={onCancel} style={{ layout: "vertical", color: "gold", shape: "pill", label: "pay", height: 40 }} />
                </div>
            </PayPalScriptProvider>
            <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
        </>
    ) : null;
};

export default PaypalCheckOut;
