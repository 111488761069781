import { Box, Card, CardContent, CardHeader, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomerLayout } from "./layouts/dashboard/customerlayout";
import Leagues from "../components/Catalogs/League.js";
import Packages from "../components/Catalogs/Packages";
import { useDispatch, useSelector } from "react-redux";
import { getPackageById } from "../actions/packages";
import { logout } from "../redux/customerSlice";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const Page = () => {
    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0,
                    width: "100%",
                }}
            >
                <Container maxWidth="xl">
                    <Typography color="text.secondary" variant="overline" style={{ fontSize: "24px", color: "black" }}>
                        {"Catalogs"}
                    </Typography>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} sm={6} lg={6} mt={0} key={"package"}>
                            <Packages />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6} mt={0} key={"league"}>
                            <Leagues />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

Page.getLayout = (page) => <CustomerLayout>{page}</CustomerLayout>;
export default Page;
