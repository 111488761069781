import PropTypes from "prop-types";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const Pick = (props) => {
    const { sx, pick, pickAnalisis, league } = props;

    return pick ? (
        <Card sx={sx}>
            <CardContent>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Typography variant="overline" style={{ fontSize: "18px", color: "black", fontWeight: "bold" }}>
                        {pick}
                    </Typography>
                </Stack>
                {pickAnalisis.toString().length > 0 ? (
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                                <Stack alignItems="center" direction="row" spacing={0.5}>
                                    <Typography color="text.primary" variant="overline" style={{ color: "#FFBF00", fontSize: "18px", fontWeight: "bold" }}>
                                        {"Pick Analysis"}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                                <Stack alignItems="center" direction="row" spacing={0.5}>
                                    <Typography color="text.secondary" variant="overline">
                                        {pickAnalisis}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                ) : null}
            </CardContent>
        </Card>
    ) : null;
};

Pick.prototypes = {
    difference: PropTypes.number,
    positive: PropTypes.bool,
    sx: PropTypes.object,
    value: PropTypes.string.isRequired,
};
