import axios from "axios";

//const API = axios.create({ baseURL: "http://localhost:5001", withCredentials: true });
const API = axios.create({ baseURL: "https://api.themaingategroup.com", withCredentials: true });

//CUSTOMER
export const getPicks = (email, session, token) => API.post("/customer/picks", { email: email, sessionId: session, token: token });

//ADMIN
export const getPicksAddInfo = (id, token) => API.post("/admin/picksaddinfo", { id: id, token: token });
export const addUpdatePick = (pickInfo, token) => API.post("/admin/addupdatepick", { pickInfo: pickInfo, token: token });
export const getPicksListAdmin = (status, league, start, length, token) => API.post("/admin/pickslistadmin", { status, league, start, length, token });
