import * as api from "../api/packages.js";

export const getPackages = (email, token, callback) => async (dispatch) => {
    const { data } = await api.getPackages(email, token);
    callback(data);
};

export const getPackagesNoToken = (callback) => async (dispatch) => {
    const { data } = await api.getPackagesNoToken();
    callback(data);
};

export const getPackageById = (id, type, token, callback) => async (dispatch) => {
    const { data } = await api.getPackageById(id, type, token);
    callback(data);
};

export const getBuyPackagesList = (name, start, length, token, callback) => async (dispatch) => {
    const { data } = await api.getBuyPackagesList(name, start, length, token);
    callback(data);
};
