import axios from "axios";

//const API = axios.create({ baseURL: "http://localhost:5001", withCredentials: true });
const API = axios.create({ baseURL: "https://api.themaingategroup.com", withCredentials: true });

export const getPaymentMehtods = (token) => API.post("/customer/getpaymentmethods", { token: token });

export const continuePaymentMethod = (email, id, orderID, packageType, token) => API.post("/customer/continuepaymentmethod", { email, id, orderID, packageType, token });

export const getPayOrderRequest = (id, token) => API.post("/customer/getpayorderrequest", { id, token });
