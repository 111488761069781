import { Autocomplete, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/customerSlice";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { getCustomersList } from "../../actions/customer";
import SearchIcon from "@mui/icons-material/Search";
import { getBuyPackagesList } from "../../actions/packages";

const useStyles = makeStyles((theme) => ({
    tableResponsive: {
        width: "100%",
        marginTop: "0px",
        overflowX: "auto",
        overflow: "auto",
    },
    tableHeadCell: {
        color: "inherit",
        "&, &$tableCell": {
            fontSize: "16px",
            fontWeight: "bold",
        },
    },
    tableHeadRow: {
        height: "36px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle",
    },
    tableCell: {
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "14px",
    },
    tableRow: {
        position: "relative",
        borderBottom: "1px solid #9dc09d",
    },
    tableCellSub: {
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "right",
    },
    tableCellSubAmt: {
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "16px",
        fontWeight: "bold",
        textAlign: "right",
    },
    tableCellTotal: {
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "18px",
        color: "green",
        fontWeight: "bold",
        textAlign: "right",
    },
    tableCellTotalAmt: {
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "18px",
        color: "green",
        fontWeight: "bold",
        textAlign: "right",
    },
}));

const BuyPackages = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.customer.token);
    const [orders, setOrders] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [name, setName] = useState("");
    const [totalAmount, setTotalAmount] = useState(0);
    const [length, setLength] = useState(25);
    const classes = useStyles();
    let start = 0;
    let subTotal = 0;

    let tableHead = ["Customer", "Package", "Method", "Status", "OrderId", "Amount", "Created"];

    const onChangeName = (e) => {
        //console.log("change name", e.target.value);
        e.preventDefault();
        setName(e.target.value);

        dispatch(
            getBuyPackagesList(e.target.value, start, length, token, (data) => {
                if (!data.error) {
                    setOrders(data.data.orders);
                    setRecordsTotal(data.data.total);
                    setTotalAmount(data.data.totalAmount);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    };

    const handleChangePage = (event, newPage) => {
        start = length * newPage;
        dispatch(
            getBuyPackagesList(name, start, length, token, (data) => {
                //console.log("change page");
                if (!data.error) {
                    setOrders(data.data.orders);
                    setRecordsTotal(data.data.total);
                    setTotalAmount(data.data.totalAmount);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLength(parseInt(event.target.value, length));
        setPage(0);
    };

    useEffect(() => {
        document.body.style.cursor = "wait";
        dispatch(
            getBuyPackagesList(name, start, length, token, (data) => {
                document.body.style.cursor = "default";
                //console.log("EFFECT");
                if (!data.error) {
                    setOrders(data.data.orders);
                    setRecordsTotal(data.data.total);
                    setTotalAmount(data.data.totalAmount);
                } else {
                    if (data.error) {
                        //console.log("TOKEN MURIO");
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
        const interval = setInterval(() => {
            dispatch(
                getBuyPackagesList(name, start, length, token, (data) => {
                    document.body.style.cursor = "default";
                    //console.log("EFFECT");
                    if (!data.error) {
                        setOrders(data.data.orders);
                        setRecordsTotal(data.data.total);
                        setTotalAmount(data.data.totalAmount);
                    } else {
                        if (data.error) {
                            //console.log("TOKEN MURIO");
                            clearInterval(interval);
                            if (data.message === "Token Expire") {
                                dispatch(logout);
                                navigate("/");
                            }
                        }
                    }
                })
            );
        }, 80000);
    }, []);

    return orders ? (
        <TableContainer>
            <Table className={classes.tableResponsive}>
                <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                        <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={"leagues"} colSpan={7}>
                            <TextField
                                label="Search Customer"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={onChangeName}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableHeadRow}>
                        {tableHead.map((name, key) => {
                            return (
                                <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                                    {name.toString()}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map((row) => {
                        subTotal += row.amount;
                        return (
                            <TableRow key={row._id} className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>{row.customer}</TableCell>
                                <TableCell className={classes.tableCell}>{row.packageName}</TableCell>
                                <TableCell className={classes.tableCell}>{row.method}</TableCell>
                                <TableCell className={classes.tableCell}>{row.status}</TableCell>
                                <TableCell className={classes.tableCell}>{row.orderId}</TableCell>
                                <TableCell className={classes.tableCell}>{`$${row.amount}`}</TableCell>
                                <TableCell className={classes.tableCell}>{moment(row.createdAt).format("MM/DD/YYYY hh:mm a")}</TableCell>
                            </TableRow>
                        );
                    })}
                    <TableRow key={"sub1"} className={classes.tableRow}>
                        <TableCell className={classes.tableCell}></TableCell>
                        <TableCell className={classes.tableCell}></TableCell>
                        <TableCell className={classes.tableCell}></TableCell>
                        <TableCell className={classes.tableCell}></TableCell>
                        <TableCell
                            sx={{
                                color: "black",
                                fontSize: "16px",
                                fontWeight: "bold",
                            }}
                        >
                            Sub Total
                        </TableCell>
                        <TableCell
                            sx={{
                                color: "black",
                                fontSize: "16px",
                                fontWeight: "bold",
                            }}
                        >{`$${subTotal}`}</TableCell>
                        <TableCell className={classes.tableCell}></TableCell>
                    </TableRow>
                    <TableRow key={"total1"} className={classes.tableRow}>
                        <TableCell className={classes.tableCell}></TableCell>
                        <TableCell className={classes.tableCell}></TableCell>
                        <TableCell className={classes.tableCell}></TableCell>
                        <TableCell className={classes.tableCell}></TableCell>
                        <TableCell
                            sx={{
                                color: "green",
                                fontSize: "18px",
                                fontWeight: "bold",
                            }}
                        >
                            Grand Total
                        </TableCell>
                        <TableCell
                            sx={{
                                color: "green",
                                fontSize: "18px",
                                fontWeight: "bold",
                            }}
                        >{`$${totalAmount}`}</TableCell>
                        <TableCell className={classes.tableCell}></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <TablePagination component="div" count={recordsTotal} page={page} onPageChange={handleChangePage} rowsPerPage={length} onRowsPerPageChange={handleChangeRowsPerPage} />
        </TableContainer>
    ) : null;
};

export default BuyPackages;
