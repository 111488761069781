import * as api from "../api/paymentMethods.js";

export const getPaymentMehtods = (token, callback) => async (dispatch) => {
    const { data } = await api.getPaymentMehtods(token);
    callback(data);
};

export const continuePaymentMethod = (email, id, orderID, packageType, token, callback) => async (dispatch) => {
    const { data } = await api.continuePaymentMethod(email, id, orderID, packageType, token);
    callback(data);
};

export const getPayOrderRequest = (id, token, callback) => async (dispatch) => {
    const { data } = await api.getPayOrderRequest(id, token);
    callback(data);
};
