import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[800]),
                p: 1,
                width: "100%",
            }}
            component="footer"
        >
            <Container maxWidth="xl">
                <Typography variant="body2" color="text.secondary" align="center">
                    {"Copyright © "}
                    <Link color="inherit" href="http://themaingroupgate.com/">
                        TheMainGateGroup.com
                    </Link>
                    {"          "}
                    {new Date().getFullYear()}
                    {".                "}
                    <Link color="inherit" href="mailto:admin@themaingategroup.com">
                        Email: admin@themaingategroup.com
                    </Link>{" "}
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
