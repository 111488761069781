import * as api from "../api/picks.js";

//CUSTOMER
export const getPicks = (email, session, token, callback) => async (dispatch) => {
    const { data } = await api.getPicks(email, session, token);
    callback(data);
};

//ADMIN
export const getPicksAddInfo = (id, token, callback) => async (dispatch) => {
    const { data } = await api.getPicksAddInfo(id, token);
    callback(data);
};
export const addUpdatePick = (pickInfo, token, callback) => async (dispatch) => {
    const { data } = await api.addUpdatePick(pickInfo, token);
    callback(data);
};
export const getPicksListAdmin = (status, league, start, length, token, callback) => async (dispatch) => {
    const { data } = await api.getPicksListAdmin(status, league, start, length, token);
    callback(data);
};
