import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import thunk from "redux-thunk";
import reducers from "./redux";
import { createStore, applyMiddleware, compose } from "redux";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "./theme";
import { BrowserRouter } from "react-router-dom";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["admin", "customer"],
};

const persistGlobalReducer = persistReducer(persistConfig, reducers);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(persistGlobalReducer, composeEnhancers(applyMiddleware(thunk)));

export const persistor = persistStore(store);

const theme = createTheme();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </BrowserRouter>
);
