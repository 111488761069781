import axios from "axios";

//const API = axios.create({ baseURL: "http://localhost:5001", withCredentials: true });
const API = axios.create({ baseURL: "https://api.themaingategroup.com", withCredentials: true });

export const getMarqueesList = (start, length, token) => API.post("/admin/getmarqueeslist", { start, length, token });

export const updateMarqueeItem = (item, token) => API.post("/admin/updatemarqueeitem", { item, token });

export const updateMarqueeSettings = (settings, token) => API.post("/admin/updatemarqueesettings", { settings, token });

export const getMarqueesIndex = (token) => API.post("/customer/marqueeslistindex", {});
