import axios from "axios";

//const API = axios.create({ baseURL: "http://localhost:5001", withCredentials: true });
const API = axios.create({ baseURL: "https://api.themaingategroup.com", withCredentials: true });

export const getPackages = (email, token) => API.post("/customer/packages", { email: email, token: token });

export const getPackagesNoToken = (token) => API.post("/customer/packagesnotoken", {});

export const getPackageById = (id, type, token) => API.post("/customer/packagesbyid", { id: id, type: type, token: token });

export const getBuyPackagesList = (name, start, length, token) => API.post("/admin/packagesbuylist", { name, start, length, token });
