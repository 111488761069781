import { Autocomplete, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPicksListAdmin } from "../../actions/picks";
import { logout } from "../../redux/customerSlice";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    tableResponsive: {
        width: "100%",
        marginTop: "0px",
        overflowX: "auto",
        overflow: "auto",
    },
    tableHeadCell: {
        color: "inherit",
        "&, &$tableCell": {
            fontSize: "16px",
            fontWeight: "bold",
        },
    },
    tableHeadRow: {
        height: "36px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle",
    },
    tableCell: {
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "14px",
    },
    tableRow: {
        position: "relative",
        borderBottom: "1px solid #9dc09d",
    },
}));

const PicksList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.customer.token);
    const [picks, setPicks] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [packages, setPackages] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [status, setStatus] = useState("active");
    const [league, setLeague] = useState("");
    const [length, setLength] = useState(25);
    const classes = useStyles();
    let start = 0;

    let statusList = [
        { value: "active", name: "Active" },
        { value: "deactived", name: "Deactived" },
        { value: "both", name: "Both" },
    ];

    let tableHead = ["Pick", "Analisis", "League", "Active", "Result", "Expiration"];

    useEffect(() => {
        dispatch(
            getPicksListAdmin(status, league, start, length, token, (data) => {
                if (!data.error) {
                    setPicks(data.data.picks);
                    setPackages(data.data.packages);
                    setLeagues(data.data.leagues);
                    setRecordsTotal(data.data.total);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    const onChangeLeague = (e, value) => {
        e.preventDefault();
        dispatch(
            getPicksListAdmin(status, value._id.toString(), start, length, token, (data) => {
                //console.log(data);
                if (!data.error) {
                    setPicks(data.data.picks);
                    setPackages(data.data.packages);
                    setLeagues(data.data.leagues);
                    setRecordsTotal(data.total);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    };

    const onChangeStatus = (e, value) => {
        e.preventDefault();
        dispatch(
            getPicksListAdmin(value.value, league, start, length, token, (data) => {
                //console.log(data);
                if (!data.error) {
                    setPicks(data.data.picks);
                    setPackages(data.data.packages);
                    setLeagues(data.data.leagues);
                    setRecordsTotal(data.total);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    };
    const handleChangePage = (event, newPage) => {
        start = length * newPage;
        dispatch(
            getPicksListAdmin(status, league, start, length, token, (data) => {
                //console.log(data);
                if (!data.error) {
                    setPicks(data.data.picks);
                    setPackages(data.data.packages);
                    setLeagues(data.data.leagues);
                    setRecordsTotal(data.total);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLength(parseInt(event.target.value, length));
        setPage(0);
    };

    const onToggleEditMode = (id) => {
        navigate(`/adminpicks?id=${id.toString()}`);
    };

    const stringPart = (st, num) => {
        return st.toString().substring(0, num) + "...";
    };

    const resultToString = (st) => {
        var ret = "";

        switch (st) {
            case "pending":
                ret = "Pending";
                break;
            case "loss":
                ret = "Loss";
                break;
            case "win":
                ret = "Win";
                break;
            case "noaction":
                ret = "No Action";
                break;
            case "postponed":
                ret = "Postponed";
                break;
            default:
                ret = "";
                break;
        }
        return ret;
    };

    return picks ? (
        <TableContainer>
            <Table className={classes.tableResponsive}>
                <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                        <TableCell align="left" className={classes.tableHeadRow} />
                        <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={"leagues"} colSpan={3}>
                            <Autocomplete id="leagues" style={{ width: 250 }} options={leagues} isOptionEqualToValue={(option, value) => option.value === value.value} getOptionLabel={(option) => option.name} renderInput={(params) => <TextField {...params} label="League" margin="normal" />} onChange={onChangeLeague} />
                        </TableCell>
                        <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={"status"} colSpan={3}>
                            <Autocomplete id="status" style={{ width: 250 }} options={statusList} isOptionEqualToValue={(option, value) => option.value === value.value} getOptionLabel={(option) => option.name} renderInput={(params) => <TextField {...params} label="Status" margin="normal" />} onChange={onChangeStatus} />
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableHeadRow}>
                        <TableCell align="left" className={classes.tableHeadRow} />
                        {tableHead.map((prop, key) => {
                            return (
                                <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                                    {prop}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {picks.map((row) => (
                        <TableRow key={row._id} className={classes.tableRow}>
                            <TableCell className={classes.selectTableCell}>
                                <IconButton aria-label="delete" onClick={() => onToggleEditMode(row._id)}>
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell className={classes.tableCell}>{stringPart(row.pick, 10)}</TableCell>
                            <TableCell className={classes.tableCell}>{stringPart(row.pickAnalisis, 10)}</TableCell>
                            <TableCell className={classes.tableCell}>{row.league.name}</TableCell>
                            <TableCell className={classes.tableCell}>{row.active.toString()}</TableCell>
                            <TableCell className={classes.tableCell}>{resultToString(row.result)}</TableCell>
                            <TableCell className={classes.tableCell}>{moment(row.expiration).format("MM/DD/YYYY hh:mm a")}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination component="div" count={recordsTotal ? recordsTotal : 0} page={page} onPageChange={handleChangePage} rowsPerPage={length} onRowsPerPageChange={handleChangeRowsPerPage} />
        </TableContainer>
    ) : null;
};

export default PicksList;
