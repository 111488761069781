import React, { useState, useEffect } from "react";
import { TextField, Card as CardGrid, CardContent, Button, Stack, Typography } from "@mui/material";
import { CustomerLayout } from "../layouts/dashboard/customerlayout";
import Card from "react-credit-cards";
import InputMask from "react-input-mask";
import "react-credit-cards/es/styles-compiled.css";
import "./styles.css";
import { useStyles } from "../../assets/styles/components/global.js";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/customerSlice.js";
import { useNavigate } from "react-router-dom";
import { getPayOrderRequest } from "../../actions/paymentMethods";
import { useParams } from "react-router-dom";

const Page = (props) => {
    const { sx } = props;
    const [cvc, setCvc] = useState("");
    const [expiry, setExpiry] = useState("");
    const [focused, setFocused] = useState("");
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.customer.token);
    const [requestOrder, setRequestOrder] = useState(null);
    let { id } = useParams();

    const handleInputFocus = ({ target }) => {
        setFocused(target.id);
    };

    const handlePay = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        dispatch(
            getPayOrderRequest(id, token, (data) => {
                if (!data.error) {
                    setRequestOrder(data.data);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    return requestOrder ? (
        <CardGrid sx={{ maxWidth: 345 }}>
            <CardContent>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Typography color="text.secondary" variant="overline" sx={{ fontSize: "24px" }}>
                        {`Pay Order $${requestOrder.amount}`}
                    </Typography>
                </Stack>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                        <Card
                            locale={{
                                valid: "Valid",
                            }}
                            placeholders={{
                                name: "Card Name",
                            }}
                            number={number}
                            name={name}
                            expiry={expiry}
                            cvc={cvc}
                            focused={focused}
                            callback={console.log}
                        />
                    </Stack>
                </Stack>
                <InputMask mask="9999 9999 9999 9999" value={number} onChange={(e) => setNumber(e.target.value)} disabled={false} maskChar=" ">
                    {() => <TextField id="number" fullWidth label="Card Number" onFocusCapture={handleInputFocus} style={{ marginTop: "10px" }} />}
                </InputMask>
                <TextField id="name" fullWidth label="Card Name" value={name} onChange={(e) => setName(e.target.value)} onFocusCapture={handleInputFocus} style={{ marginTop: "5px" }} />
                <InputMask mask="99/99" value={expiry} onChange={(e) => setExpiry(e.target.value)} disabled={false} maskChar=" ">
                    {() => <TextField id="expiry" fullWidth label="Expire" onFocusCapture={handleInputFocus} style={{ marginTop: "10px" }} />}
                </InputMask>
                <InputMask mask="999" value={cvc} onChange={(e) => setCvc(e.target.value)} disabled={false} maskChar=" ">
                    {() => <TextField id="cvc" fullWidth label="CVC" onFocusCapture={handleInputFocus} style={{ marginTop: "10px" }} />}
                </InputMask>
                <Stack alignItems="flex-end" direction="row" justifyContent="space-between" spacing={3}>
                    <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" onClick={handlePay} className={classes.submit}>
                        Buy
                    </Button>
                </Stack>
            </CardContent>
        </CardGrid>
    ) : null;
};

Page.getLayout = (page) => <CustomerLayout>{page}</CustomerLayout>;
export default Page;
