import axios from "axios";

//const API = axios.create({ baseURL: "http://localhost:5001", withCredentials: true });
const API = axios.create({ baseURL: "https://api.themaingategroup.com", withCredentials: true });

export const validateName = (name) => API.post("/customer/validatename", { name: name });
export const validateEmail = (email) => API.post("/customer/validateemail", { email: email });
export const validateLogin = (email, password) => API.post("/customer/validatelogin", { email: email, password: password });
export const signUpCustomer = (name, password, email) => API.post("/customer/signupcustomer", { name: name, password: password, email: email });
export const getCustomersList = (name, status, start, length, token) => API.post("/admin/getcustomerslist", { name, status, start, length, token });
export const updateTwoFactorValue = (email, token) => API.post("/customer/updatetwofactor", { email: email, token: token });
export const updatePassword = (postData, token) => API.post("/customer/updatepassword", { postData: postData, token: token });
export const getCustomersByFilter = (filterCustomers, token) => API.post("/admin/allcustomerslist", { filterCustomers, token });

export const updateCustomer = (customer, token) => API.post("/admin/updatecustomer", { customer: customer, token: token });
export const updateCustomerPackage = (pkg, token) => API.post("/admin/updatecustomerpackage", { pkg: pkg, token: token });
export const addCustomerPackage = (pkg, token) => API.post("/admin/addcustomerpackage", { pkg: pkg, token: token });
export const getCustomerInfo = (id, token) => API.post("/admin/customerinfo", { id: id, token: token });
