import PropTypes from "prop-types";
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Stack, Typography } from "@mui/material";
import PayMethod from "../views/payMethod.js";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../assets/styles/components/global.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const PackageIndex = (props) => {
    const { sx, packageName, imagesrc, bioTitle, bio, price, id } = props;
    const navigate = useNavigate();
    const classes = useStyles();

    const handleBuy = (e) => {
        e.preventDefault();
        navigate(`/paymethod?id=${id}`);
    };

    return (
        <Card sx={sx}>
            <CardContent>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Typography color="text.secondary" variant="overline">
                        {packageName}
                    </Typography>
                </Stack>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                        <img src={`/img/${imagesrc}`} alt="" width={"98%"} height={"98%"} />
                    </Stack>
                </Stack>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    {bio.toString().length > 0 ? (
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                                    <Stack alignItems="center" direction="row" spacing={0.5}>
                                        <Typography color="text.primary" variant="overline" style={{ color: "#235E2D", fontSize: "16px", fontWeight: "bold" }}>
                                            {bioTitle}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                                    <Stack alignItems="center" direction="row" spacing={0.5}>
                                        <Typography color="text.secondary" variant="overline" sx={{ textAlign: "justify" }}>
                                            {bio}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    ) : null}
                </Stack>
                <Stack alignItems="flex-end" direction="row" justifyContent="space-between" spacing={3}>
                    <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" onClick={handleBuy} className={classes.submit}>
                        Buy
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    );
};

PackageIndex.prototypes = {
    difference: PropTypes.number,
    positive: PropTypes.bool,
    sx: PropTypes.object,
    value: PropTypes.string.isRequired,
};
