import React, { useEffect, useState } from "react";
import { CustomerLayout } from "./layouts/dashboard/customerlayout";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { paymentAcceptProcess } from "../actions/paypal";
import { logout } from "../redux/customerSlice";
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, CardContent, Container, Grid, Stack, Typography } from "@mui/material";

const Page = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const tokenOrder = searchParams.get("token");
    const payerIdOrder = searchParams.get("PayerID");
    const method = searchParams.get("method");
    const type = searchParams.get("type");

    const token = useSelector((state) => state.customer.token);
    const email = useSelector((state) => state.customer.email);
    const [packageBuy, setPackageBuy] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(
            paymentAcceptProcess(email, tokenOrder, payerIdOrder, method, type, token, (data) => {
                if (!data.error) {
                    setPackageBuy(data.data);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    const handleGoToPicks = (e) => {
        e.preventDefault();
        navigate("/picks");
    };

    return packageBuy ? (
        <Card sx={{ maxWidth: 345 }}>
            <CardContent>
                <Stack alignItems="center" direction="column" justifyContent="space-between" spacing={3}>
                    <Typography color="text.secondary" variant="overline" sx={{ fontSize: "24px", color: "black", fontWeight: "bold" }}>
                        {"Payment Accepted"}
                    </Typography>
                </Stack>
                <Stack alignItems="center" direction="column" justifyContent="space-between" spacing={3}>
                    <Typography color="text.secondary" variant="overline" sx={{ fontSize: "14px" }}>
                        {`${packageBuy.name} Package`}
                    </Typography>
                </Stack>
                <Stack alignItems="center" direction="column" justifyContent="space-between" spacing={3}>
                    <Stack alignItems="center" direction="column" spacing={0.5}>
                        <img src={`/img/${packageBuy.img}`} alt="" width={"80%"} height={"80%"} />
                    </Stack>
                </Stack>
                <Stack alignItems="center" direction="column" justifyContent="space-between" spacing={3}>
                    <Typography color="text.secondary" variant="overline" sx={{ fontSize: "22px", color: "black", fontWeight: "bold" }}>
                        {`PAID: $${parseFloat(packageBuy.amount).toFixed(2)}`}
                    </Typography>
                </Stack>
                <Stack alignItems="center" direction="column" justifyContent="space-between" spacing={3}>
                    <Button
                        fullWidth
                        sx={{ mt: 3 }}
                        onClick={handleGoToPicks}
                        style={{
                            backgroundColor: "#FFCC33",
                            color: "#000000",
                            fontWeight: "bold",
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "#FFBF00",
                                color: "#000000",
                            },
                        }}
                    >
                        Go to your Picks
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    ) : null;
};

Page.getLayout = (page) => <CustomerLayout>{page}</CustomerLayout>;
export default Page;
