import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Box, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { Logo } from "../../../components/logo.js";
import ImgBackGround from "../../../assets/img/maingategroup_white.png";
import LoginPackages from "../../auth/LoginPackages.js";
import Footer from "../../../components/Footer/Footer.js";
import MarqueeShow from "../../../components/Marquee/MarqueeShow.js";

// TODO: Change subtitle text

export const Layout = (props) => {
    const { children } = props;

    return (
        <div>
            <Box
                component="main"
                sx={{
                    display: "flex",
                    flex: "1 1 auto",
                    height: "1280px",
                }}
            >
                <Grid container sx={{ flex: "1 1 auto" }}>
                    <Grid
                        xs={12}
                        lg={6}
                        p={0}
                        sx={{
                            backgroundColor: "background.paper",
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                        }}
                    >
                        <img src={ImgBackGround} alt=""></img>
                    </Grid>
                    <Grid
                        xs={12}
                        lg={6}
                        p={0}
                        sx={{
                            backgroundColor: "background.paper",
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            height: "36%",
                            textAlign: "top",
                        }}
                    >
                        {children}
                    </Grid>
                    <Grid
                        xs={12}
                        lg={12}
                        p={0}
                        sx={{
                            backgroundColor: "background.paper",
                            display: "flex",
                            flexDirection: "row",
                            position: "relative",
                        }}
                    >
                        <MarqueeShow />
                    </Grid>
                    <Grid
                        xs={12}
                        lg={12}
                        p={0}
                        sx={{
                            backgroundColor: "background.paper",
                            display: "flex",
                            flexDirection: "row",
                            position: "relative",
                        }}
                    >
                        <LoginPackages />
                    </Grid>
                    <Grid
                        xs={12}
                        lg={12}
                        p={0}
                        sx={{
                            backgroundColor: "background.paper",
                            display: "flex",
                            flexDirection: "row",
                            position: "relative",
                        }}
                    >
                        <Footer />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

Layout.prototypes = {
    children: PropTypes.node,
};
