import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { AdminLayout } from "./layouts/dashboard/adminlayout";
import AdminListPicks from "../components/Tables/PicksList.js";

const Page = (props) => {
    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0,
                }}
            >
                <Container maxWidth="xl">
                    <Typography color="text.secondary" variant="overline" style={{ fontSize: "16px" }}>
                        {"Admin List Picks"}
                    </Typography>
                    <Grid container spacing={3} mt={5} justifyContent="center">
                        <AdminListPicks />
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

Page.getLayout = (page) => <AdminLayout>{page}</AdminLayout>;
export default Page;
