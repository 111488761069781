import PropTypes from "prop-types";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import PayMethod from "../views/payMethod.js";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../assets/styles/components/global.js";
import { min } from "moment";

export const Package = (props) => {
    const { sx, packageName, imagesrc, description, price, id, prices } = props;
    const navigate = useNavigate();
    const classes = useStyles();

    const handleBuy = (type) => {
        navigate(`/paymethod?id=${id}&type=${type}`);
    };

    const pricesHtml = prices.map((element, key) => {
        return (
            <Button size="small" sx={{ mt: 0, maxWidth: "25%", maxHeight: "15%", minWidth: "5%", minHeight: "5%", fontSize: "70%" }} type="submit" onClick={() => handleBuy(element.type)} className={classes.submit} key={key}>
                {`${element.name} $${element.price}`}
            </Button>
        );
    });

    return (
        <Card sx={sx}>
            <CardContent>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Typography color="text.secondary" variant="overline">
                        {packageName}
                    </Typography>
                </Stack>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                        <img src={`/img/${imagesrc}`} alt="" width={"98%"} height={"98%"} />
                    </Stack>
                </Stack>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                        <Typography color="text.secondary" variant="overline" sx={{ textAlign: "justify" }}>
                            {description}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={1} sx={{ maxWidth: "100%" }}>
                    {pricesHtml}
                </Stack>
            </CardContent>
        </Card>
    );
};

Package.prototypes = {
    difference: PropTypes.number,
    positive: PropTypes.bool,
    sx: PropTypes.object,
    value: PropTypes.string.isRequired,
};
