import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { AdminLayout } from "./layouts/dashboard/adminlayout";
import Marquees from "../components/Marquee/Marquee";

const Page = () => {
    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0,
                    width: "100%",
                }}
            >
                <Container maxWidth="xl">
                    <Typography color="text.secondary" variant="overline" style={{ fontSize: "24px", color: "black" }}>
                        {"Marquee Settings"}
                    </Typography>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} sm={6} lg={6} mt={0} key={"marquee"}>
                            <Marquees />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

Page.getLayout = (page) => <AdminLayout>{page}</AdminLayout>;
export default Page;
