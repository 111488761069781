import { Autocomplete, Box, Button, CardHeader, Checkbox, Container, FormControlLabel, FormGroup, Grid, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { AdminLayout } from "./layouts/dashboard/adminlayout";
import { Package } from "../sections/package";
import { useDispatch, useSelector } from "react-redux";
import { addUpdatePick, getPicksAddInfo } from "../actions/picks.js";
import { logout } from "../redux/customerSlice.js";
import { useNavigate } from "react-router-dom";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import OkDialog from "../components/Dialogs/OkDialog";
import { useSearchParams } from "react-router-dom";

const Page = (props) => {
    let [searchParams, setSearchParams] = useSearchParams();
    let id = "";
    if (searchParams.get("id")) {
        id = searchParams.get("id");
    }
    const token = useSelector((state) => state.customer.token);
    const [packages, setPackages] = useState([]);
    const [actualLeague, setActualLeague] = useState("");
    const [picks, setPicks] = useState([]);
    const [pick, setPick] = useState(null);
    const [leagues, setLeagues] = useState([]);
    const [okDialog, setOkDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        isError: false,
    });
    const [pickInfo, setPickInfo] = useState({
        id: "",
        pick: "",
        pickAnalisis: "",
        league: "",
        expiration: dayjs(new Date()),
        active: true,
        packages: [],
        result: { value: "pending", name: "Pending" },
    });
    let results = [
        { value: "pending", name: "Pending" },
        { value: "win", name: "Win" },
        { value: "loss", name: "Loss" },
        { value: "noaction", name: "No Action" },
        { value: "postponed", name: "Postponed" },
    ];
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(
            getPicksAddInfo(id, token, (data) => {
                //console.log(data);
                if (!data.error) {
                    setPackages(data.data.packages);
                    setPicks(data.data.picks);
                    setLeagues(data.data.leagues);
                    if (data.data.pick) {
                        setPickInfo({
                            id: data.data.pick._id.toString(),
                            pick: data.data.pick.pick,
                            pickAnalisis: data.data.pick.pickAnalisis,
                            league: data.data.pick.league._id.toString(),
                            expiration: dayjs(data.data.pick.expiration),
                            active: data.data.pick.active,
                            packages: data.data.pick.packages,
                            result: results.find((r) => r.value === data.data.pick.result),
                        });
                        setPick(data.data.pick.league);
                    }
                    //console.log("Leagues", data.data.leagues);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    const onChangeLeague = (e, value) => {
        e.preventDefault();
        setPickInfo({ ...pickInfo, league: value._id.toString() });
    };

    const onChangeResult = (e, value) => {
        e.preventDefault();
        setPickInfo({ ...pickInfo, result: value.value.toString() });
    };

    const onCheckedPackage = (e) => {
        e.preventDefault();
        let arrTemp = pickInfo.packages.slice();
        //console.log("Check Package", e.target.id);
        if (e.target.checked) {
            if (!arrTemp.includes(e.target.id.toString())) {
                arrTemp.push(e.target.id.toString());
                setPickInfo({ ...pickInfo, packages: arrTemp });
            }
        } else {
            arrTemp = arrayRemove(arrTemp, e.target.id.toString());
            setPickInfo({ ...pickInfo, packages: arrTemp });
        }

        //console.log("array final", pickInfo.packages);
    };

    const arrayRemove = (arr, value) => {
        return arr.filter(function (ele) {
            return ele != value;
        });
    };

    const onCheckedActive = (e) => {
        e.preventDefault();
        setPickInfo({ ...pickInfo, active: e.target.checked });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //console.log("Info to save", pickInfo);
        dispatch(
            addUpdatePick(pickInfo, token, (data) => {
                if (!data.error) {
                    setOkDialog({
                        isError: false,
                        isOpen: true,
                        title: "Add / Update Pick result",
                        subTitle: data?.message,
                        onConfirm: () => {
                            setOkDialog({
                                ...okDialog,
                                isOpen: false,
                            });
                        },
                    });
                    if (pickInfo.id.length > 0) navigate("/adminpickslist");
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        } else {
                            setOkDialog({
                                isError: true,
                                isOpen: true,
                                title: "Add / Update Pick result",
                                subTitle: data?.message,
                                onConfirm: () => {
                                    setOkDialog({
                                        ...okDialog,
                                        isOpen: false,
                                    });
                                },
                            });
                        }
                    }
                }
            })
        );
    };

    const onExpitrationChange = (newValue) => {
        //console.log(dayjs(newValue).format("YYYY-MM-DD HH:mm:ss"));
        setPickInfo({ ...pickInfo, expiration: dayjs(newValue).format("YYYY-MM-DD HH:mm:ss") });
    };

    return packages ? (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0,
                }}
            >
                <Container maxWidth="xl">
                    <Typography color="text.secondary" variant="overline" style={{ fontSize: "16px" }}>
                        {"Add Update Picks"}
                    </Typography>
                    <Grid container spacing={3} justifyContent="center">
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell width={"15%"}>{"Pick:"}</TableCell>
                                        <TableCell width={"85%"}>
                                            <TextField
                                                rows={3}
                                                fullWidth
                                                multiline
                                                //helperText={"Pick description"}
                                                label="Pick Description"
                                                name="pick"
                                                onChange={(e) => {
                                                    setPickInfo({ ...pickInfo, pick: e.target.value });
                                                }}
                                                type="text"
                                                value={pickInfo.pick}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"15%"}>{"Pick Analisis:"}</TableCell>
                                        <TableCell width={"85%"}>
                                            <TextField
                                                rows={6}
                                                fullWidth
                                                multiline
                                                //helperText={"Pick analisis"}
                                                label="Pick Analisis"
                                                name="pickAnalisis"
                                                onChange={(e) => {
                                                    setPickInfo({ ...pickInfo, pickAnalisis: e.target.value });
                                                }}
                                                type="text"
                                                value={pickInfo.pickAnalisis}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"15%"}>{"League:"}</TableCell>
                                        <TableCell width={"85%"}>
                                            <Autocomplete value={pick} id="league" style={{ width: 250 }} options={leagues} getOptionLabel={(option) => option.name} renderInput={(params) => <TextField {...params} label="League" margin="normal" />} onChange={onChangeLeague} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"15%"}>{"Packages to apply:"}</TableCell>
                                        <TableCell width={"85%"}>
                                            {packages.map((pkg, key) => {
                                                let exists = false;
                                                if (pickInfo.packages.length > 0) {
                                                    let fd = pickInfo.packages.find((p) => p.toString() === pkg._id.toString());
                                                    if (fd) {
                                                        exists = true;
                                                    }
                                                }
                                                return <FormControlLabel control={<Checkbox id={pkg._id.toString()} onChange={onCheckedPackage} key={key} checked={exists} />} label={pkg.name} key={"Cl" + key.toString()} />;
                                            })}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"15%"}>{"Active:"}</TableCell>
                                        <TableCell width={"85%"}>
                                            <FormControlLabel control={<Checkbox id={"active"} onChange={onCheckedActive} key={"active"} checked={pickInfo.active} />} label={"Active"} key={"ClActive"} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"15%"}>{"Expiration Date:"}</TableCell>
                                        <TableCell width={"85%"}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker label="Expiration" inputFormat="MM/DD/YYYY" value={pickInfo.expiration} onChange={onExpitrationChange} renderInput={(params) => <TextField {...params} />} />
                                            </LocalizationProvider>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"15%"}>{"Result:"}</TableCell>
                                        <TableCell width={"85%"}>
                                            <Autocomplete value={pickInfo.result} id="result" style={{ width: 250 }} options={results} getOptionLabel={(option) => option.name} renderInput={(params) => <TextField {...params} label="Result" margin="normal" />} onChange={onChangeResult} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <form noValidate onSubmit={handleSubmit}>
                                                <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
                                                    {pick ? "Update" : "Save"}
                                                </Button>
                                            </form>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <OkDialog okDialog={okDialog} setOkDialog={setOkDialog} />
                </Container>
            </Box>
        </>
    ) : null;
};

Page.getLayout = (page) => <AdminLayout>{page}</AdminLayout>;
export default Page;
