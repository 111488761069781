import * as api from "../api/paypal.js";

export const paymentAcceptProcess = (email, tokenOrder, payerId, method, type, token, callback) => async (dispatch) => {
    const { data } = await api.paymentAcceptProcess(email, tokenOrder, payerId, method, type, token);
    callback(data);
};

export const paymentCancelProcess = (tokenOrder, token, callback) => async (dispatch) => {
    const { data } = await api.paymentCancelProcess(tokenOrder, token);
    callback(data);
};

export const getPaypalClientId = (token, callback) => async (dispatch) => {
    const { data } = await api.getPaypalClientId(token);
    callback(data);
};
