import { Box, Card, CardContent, CardHeader, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomerLayout } from "./layouts/dashboard/customerlayout";
import PaypalCheckOut from "../components/Paypal/PaypalCheckOut.js";
import { useDispatch, useSelector } from "react-redux";
import { getPackageById } from "../actions/packages";
import { logout } from "../redux/customerSlice";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const Page = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get("id");
    const type = searchParams.get("type");
    const token = useSelector((state) => state.customer.token);
    const [packageToBuy, setPackageToBuy] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            getPackageById(id, type, token, (data) => {
                //console.log(data);
                if (!data.error) {
                    setPackageToBuy(data.data);
                    //console.log("Data", data.data);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    return packageToBuy ? (
        <>
            <Card>
                <CardContent>
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            py: 0,
                        }}
                    >
                        <Container maxWidth="xl">
                            <Typography color="text.secondary" variant="overline" style={{ fontSize: "24px", color: "black" }}>
                                {"Payment Method"}
                            </Typography>
                            <Grid container spacing={1} direction="row">
                                <Grid item xs={12} sm={6} lg={12} mt={0} key={"ordersummary"}>
                                    <PaypalCheckOut img={packageToBuy.image} total={packageToBuy.priceByPackageType.price} name={packageToBuy.name} id={id} packageTypeName={packageToBuy.packagePriceType.name} packageType={packageToBuy.packagePriceType.type} />
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </CardContent>
            </Card>
        </>
    ) : null;
};

Page.getLayout = (page) => <CustomerLayout>{page}</CustomerLayout>;
export default Page;

/* <Grid container spacing={1} justifyContent="center" mt={"20px"}>
                        <Grid item xs={12} sm={6} lg={5} key={"PaymentCard"}>
                            <PaymentCard sx={{ height: "100%" }} pack={packageToBuy} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={5} ml={0} key={"PaymentPayPal"}>
                            <PaymentPayPal sx={{ height: "100%" }} pack={packageToBuy} />
                        </Grid>
                    </Grid> */
