import { Autocomplete, IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/customerSlice";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { getCustomersList } from "../../actions/customer";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme) => ({
    tableResponsive: {
        width: "100%",
        marginTop: "0px",
        overflowX: "auto",
        overflow: "auto",
    },
    tableHeadCell: {
        color: "inherit",
        "&, &$tableCell": {
            fontSize: "16px",
            fontWeight: "bold",
        },
    },
    tableHeadRow: {
        height: "36px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle",
    },
    tableCell: {
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "14px",
    },
    tableRow: {
        position: "relative",
        borderBottom: "1px solid #9dc09d",
    },
}));

const Customers = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.customer.token);
    const [customers, setCustomers] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [name, setName] = useState("");
    const [status, setStatus] = useState({ value: "both", name: "Both" });
    const [length, setLength] = useState(25);
    const classes = useStyles();
    let start = 0;

    let statusList = [
        { value: "active", name: "Active" },
        { value: "deactived", name: "Deactived" },
        { value: "both", name: "Both" },
    ];

    let tableHead = ["Name", "Email", "Status", "Password", "Total Packages Buy", "Total Amount Buy", "Is Admin", "Created"];

    const onChangeStatus = (e, value) => {
        //console.log("change status", e, value);
        e.preventDefault();
        setStatus(value);

        dispatch(
            getCustomersList(name, value.value, start, length, token, (data) => {
                if (!data.error) {
                    setCustomers(data.data.customers);
                    setRecordsTotal(data.data.total);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    };

    const onChangeName = (e) => {
        //console.log("change name", e.target.value);
        e.preventDefault();
        setName(e.target.value);

        dispatch(
            getCustomersList(name, e.target.value, start, length, token, (data) => {
                if (!data.error) {
                    setCustomers(data.data.customers);
                    setRecordsTotal(data.data.total);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    };

    const handleChangePage = (event, newPage) => {
        start = length * newPage;
        dispatch(
            getCustomersList(name, status, start, length, token, (data) => {
                //console.log("change page");
                if (!data.error) {
                    setCustomers(data.data.customers);
                    setRecordsTotal(data.data.total);
                } else {
                    if (data.error) {
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLength(parseInt(event.target.value));
        dispatch(
            getCustomersList(name, status, start, event.target.value, token, (data) => {
                //console.log("TIENE DATA", data.error);
                if (!data.error) {
                    //console.log("SET DATA");
                    setCustomers(data.data.customers);
                    setRecordsTotal(data.data.total);
                } else {
                    if (data.error) {
                        //console.log("TOKEN MURIO");
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    };

    useEffect(() => {
        dispatch(
            getCustomersList(name, status, start, length, token, (data) => {
                //console.log("TIENE DATA", data.error);
                if (!data.error) {
                    //console.log("SET DATA");
                    setCustomers(data.data.customers);
                    setRecordsTotal(data.data.total);
                } else {
                    if (data.error) {
                        //console.log("TOKEN MURIO");
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    const onToggleEditMode = (id) => {
        navigate(`/admincustomer?id=${id.toString()}`);
    };

    return (
        <TableContainer>
            <Table className={classes.tableResponsive}>
                <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                        <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={"leagues"} colSpan={5}>
                            <TextField
                                label="Search Name"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={onChangeName}
                            />
                        </TableCell>
                        <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={"status"} colSpan={4}>
                            <Autocomplete id="status" style={{ width: 250 }} options={statusList} isOptionEqualToValue={(option, value) => option.value === value.value} getOptionLabel={(option) => option.name} renderInput={(params) => <TextField {...params} label="Status" margin="normal" />} onChange={onChangeStatus} />
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableHeadRow}>
                        <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={"head1"}></TableCell>
                        {tableHead.map((name, key) => {
                            //console.log(name);
                            return (
                                <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                                    {name.toString()}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                {customers ? (
                    <TableBody>
                        {customers.map((row) => (
                            <TableRow key={row._id} className={classes.tableRow}>
                                <TableCell className={classes.selectTableCell}>
                                    <IconButton aria-label="delete" onClick={() => onToggleEditMode(row._id)}>
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell className={classes.tableCell}>{row.name}</TableCell>
                                <TableCell className={classes.tableCell}>{row.email}</TableCell>
                                <TableCell className={classes.tableCell}>{row.active === true ? "Active" : "Deactived"}</TableCell>
                                <TableCell className={classes.tableCell}>{row.password}</TableCell>
                                <TableCell className={classes.tableCell}>{row.totalPicksBuy}</TableCell>
                                <TableCell className={classes.tableCell}>{row.totalPayAmount}</TableCell>
                                <TableCell className={classes.tableCell}>{row.isAdmin.toString()}</TableCell>
                                <TableCell className={classes.tableCell}>{moment(row.createdAt).format("MM/DD/YYYY hh:mm a")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                ) : null}
            </Table>
            <TablePagination component="div" count={recordsTotal} page={page} onPageChange={handleChangePage} rowsPerPage={length} onRowsPerPageChange={handleChangeRowsPerPage} />
        </TableContainer>
    );
};

export default Customers;
