import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    submit: {
        backgroundColor: "#FFCC33",
        color: "#000000",
        fontWeight: "bold",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#FFBF00",
            color: "#000000",
        },
    },
    inputStyle: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            display: "none",
        },
        textAlign: "center",
        fontSize: "36px",
        fontWeight: "bold",
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(3),
        backgroundColor: "none",
    },
    textoSec: {
        color: "black",
        fontSize: "19px",
        fontWeight: "bold",
        textAlign: "center",
    },
    textoAuth: {
        color: "black",
        fontSize: "13px",
        fontWeight: "bold",
        textAlign: "center",
    },
    sixDigit: {
        color: "black",
        fontSize: "13px",
        fontWeight: "bold",
        textAlign: "center",
    },
}));
