import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Card, CardContent, Box, Container, Typography, Grid, TextField, IconButton, FormControlLabel, Checkbox, Button, InputLabel, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/customerSlice.js";
import { getCatalogList, updateCatalogItem } from "../../actions/catalogs.js";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme) => ({
    tableResponsive: {
        width: "100%",
        marginTop: "0px",
        overflowX: "auto",
        overflow: "auto",
    },
    tableHeadCell: {
        color: "inherit",
        "&, &$tableCell": {
            fontSize: "16px",
            fontWeight: "bold",
        },
    },
    tableHeadRow: {
        height: "36px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle",
    },
    tableCell: {
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "14px",
    },
    tableRow: {
        position: "relative",
        borderBottom: "1px solid #9dc09d",
    },
}));

const Packages = () => {
    const token = useSelector((state) => state.customer.token);
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [catalog, setCatalog] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [length, setLength] = useState(25);
    const catalogName = "Packages";
    let start = 0;

    const [packageInfo, setPackageInfo] = useState({
        id: "",
        name: "",
        price: "",
        description: "",
        packageTypes: [],
        bio: "",
        bioTitle: "",
        image: "",
        active: true,
    });

    let tableHead = ["Name", "Daily", "3 Days", "5 Days", "30 Days", "Season", "Description", "Image", "Active", "Created"];

    const handleChangePage = (event, newPage) => {
        start = length * newPage;
        dispatch(
            getCatalogList(catalogName, start, length, token, (data) => {
                //console.log("TIENE DATA", data.error);
                if (!data.error) {
                    //console.log("SET DATA");
                    setCatalog(data.data.catalogs);
                    setRecordsTotal(data.data.total);
                } else {
                    if (data.error) {
                        //console.log("TOKEN MURIO");
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setLength(parseInt(event.target.value, length));
        setPage(0);
    };

    useEffect(() => {
        dispatch(
            getCatalogList(catalogName, start, length, token, (data) => {
                //console.log("TIENE DATA", data);
                if (!data.error) {
                    //console.log("SET DATA");
                    setCatalog(data.data.catalogs);
                    setRecordsTotal(data.data.total);
                } else {
                    if (data.error) {
                        //console.log("TOKEN MURIO");
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    const onToggleEditMode = (id) => {
        setPackageInfo({
            id: "",
            name: "",
            price: "",
            description: "",
            bio: "",
            bioTitle: "",
            image: "",
            active: true,
        });
        let item = catalog.find((i) => i._id.toString() === id.toString());
        setPackageInfo({
            id: item._id.toString(),
            name: item.name,
            price: item.price,
            description: item.description,
            packageTypes: item.packageTypes,
            bio: item.bio,
            bioTitle: item.bioTitle,
            image: item.image,
            active: item.active,
        });
    };

    const onCheckedActive = (e) => {
        e.preventDefault();
        setPackageInfo({ ...packageInfo, active: e.target.checked });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(
            updateCatalogItem(catalogName, packageInfo, token, (data) => {
                if (!data.error) {
                    //console.log("SET DATA", data);
                    setCatalog(data.data.catalogs);
                    setRecordsTotal(data.data.total);
                    setPackageInfo({
                        id: "",
                        name: "",
                        price: "",
                        description: "",
                        bio: "",
                        bioTitle: "",
                        image: "",
                        active: true,
                    });
                } else {
                    if (data.error) {
                        //console.log("TOKEN MURIO");
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    };

    const changePackagePrice = (e) => {
        e.preventDefault();

        var tempPackInfo = packageInfo;
        for (let z = 0; z < tempPackInfo.packageTypes.length; z++) {
            if (tempPackInfo.packageTypes[z]._id.toString() == e.target.id.toString()) {
                if (tempPackInfo.packageTypes[z].packagePrice) {
                    tempPackInfo.packageTypes[z].packagePrice.price = e.target.value;
                } else {
                    let newPrice = {
                        insert: true,
                        active: false,
                        createdAt: new Date(),
                        package: tempPackInfo.id,
                        price: parseFloat(e.target.value),
                        type: tempPackInfo.packageTypes[z]._id,
                        updatedAt: new Date(),
                    };
                    tempPackInfo.packageTypes[z].packagePrice = newPrice;
                }
            }
        }
        setPackageInfo(tempPackInfo);
        //console.log("PackageInfo", packageInfo);
    };

    const changePackageActive = (e) => {
        e.preventDefault();

        var tempPackInfo = packageInfo;
        for (let z = 0; z < tempPackInfo.packageTypes.length; z++) {
            if (tempPackInfo.packageTypes[z]._id.toString() == e.target.id.toString()) {
                if (tempPackInfo.packageTypes[z].packagePrice) {
                    tempPackInfo.packageTypes[z].packagePrice.active = e.target.checked;
                } else {
                    let newPrice = {
                        insert: true,
                        active: e.target.checked,
                        createdAt: new Date(),
                        package: tempPackInfo.id,
                        price: 0,
                        type: tempPackInfo.packageTypes[z]._id,
                        updatedAt: new Date(),
                    };
                    tempPackInfo.packageTypes[z].packagePrice = newPrice;
                }
            }
        }
        setPackageInfo(tempPackInfo);
        //console.log("PackageInfo", packageInfo);
    };

    return (
        <>
            <Card>
                <CardContent>
                    <Typography color="text.secondary" variant="overline" style={{ fontSize: "24px", color: "black" }}>
                        {"Packages"}
                    </Typography>
                    <Grid container spacing={1} direction="row">
                        <TableContainer>
                            <Table className={classes.tableResponsive}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell width={"100%"}>
                                            <TextField
                                                fullWidth
                                                label="Name"
                                                name="name"
                                                onChange={(e) => {
                                                    setPackageInfo({ ...packageInfo, name: e.target.value });
                                                }}
                                                type="text"
                                                value={packageInfo.name || ""}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"100%"}>
                                            <TextField
                                                fullWidth
                                                label="Price"
                                                name="price"
                                                onChange={(e) => {
                                                    setPackageInfo({ ...packageInfo, price: e.target.value });
                                                }}
                                                type="number"
                                                value={packageInfo.price || "0"}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"100%"}>
                                            <TextField
                                                rows={3}
                                                fullWidth
                                                multiline
                                                //helperText={"Pick description"}
                                                label="Description"
                                                name="description"
                                                onChange={(e) => {
                                                    setPackageInfo({ ...packageInfo, description: e.target.value });
                                                }}
                                                type="text"
                                                value={packageInfo.description || ""}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"100%"}>
                                            <TextField
                                                fullWidth
                                                label="Label Index"
                                                name="bioTitle"
                                                onChange={(e) => {
                                                    setPackageInfo({ ...packageInfo, bioTitle: e.target.value });
                                                }}
                                                type="text"
                                                value={packageInfo.bioTitle || ""}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"100%"}>
                                            <TextField
                                                rows={3}
                                                fullWidth
                                                multiline
                                                //helperText={"Pick description"}
                                                label="Index Description"
                                                name="bio"
                                                onChange={(e) => {
                                                    setPackageInfo({ ...packageInfo, bio: e.target.value });
                                                }}
                                                type="text"
                                                value={packageInfo.bio || ""}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"100%"}>
                                            <FormControlLabel control={<Checkbox id={"active"} onChange={onCheckedActive} checked={packageInfo.active || false} />} label={"Active"} key={"ClActive"} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={"100%"}>
                                            <TextField
                                                fullWidth
                                                label="Image"
                                                name="image"
                                                onChange={(e) => {
                                                    setPackageInfo({ ...packageInfo, image: e.target.value });
                                                }}
                                                type="text"
                                                value={packageInfo.image || ""}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {packageInfo?.packageTypes?.map((element, key) => {
                                        return (
                                            <TableRow key={key}>
                                                <TableCell width={"100%"} key={element.name}>
                                                    <FormControlLabel control={<Checkbox id={element._id} onChange={changePackageActive} key={element.name} defaultChecked={element?.packagePrice?.active == true ? true : false || false} />} label={element.name} />
                                                    <TextField size="small" name={`txt${element.name}`} id={`${element._id}`} onChange={changePackagePrice} type="text" defaultValue={element?.packagePrice?.price || ""} />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <form noValidate onSubmit={handleSubmit}>
                                                <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
                                                    {packageInfo?.id?.length > 0 ? "Update" : "Save"}
                                                </Button>
                                            </form>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table className={classes.tableResponsive}>
                                <TableHead>
                                    <TableRow className={classes.tableHeadRow}>
                                        <TableCell align="left" className={classes.tableHeadRow} />
                                        {tableHead.map((name, key) => {
                                            return (
                                                <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                                                    {name.toString()}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {catalog.map((row) => (
                                        <TableRow key={row._id} className={classes.tableRow}>
                                            <TableCell className={classes.selectTableCell}>
                                                <IconButton aria-label="delete" onClick={() => onToggleEditMode(row._id)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>{row.name}</TableCell>
                                            {row?.packageTypes.map((pr, key) => {
                                                console.log(pr);
                                                return (
                                                    <TableCell className={classes.tableCell} key={key}>
                                                        {pr?.packagePrice?.price}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell className={classes.tableCell}>{row.description}</TableCell>
                                            <TableCell className={classes.tableCell}>{row.image}</TableCell>
                                            <TableCell className={classes.tableCell}>{row.active.toString()}</TableCell>
                                            <TableCell className={classes.tableCell}>{moment(row.createdAt).format("MM/DD/YYYY hh:mm a")}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination component="div" count={recordsTotal} page={page} onPageChange={handleChangePage} rowsPerPage={length} onRowsPerPageChange={handleChangeRowsPerPage} />
                        </TableContainer>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default Packages;
