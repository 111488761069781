import { useCallback, useEffect, useState } from "react";
import { CardHeader, Container, Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Alert, Box, Button, FormHelperText, Link, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Layout as AuthLayout } from "../layouts/auth/layout";
import { validateLogin } from "../../actions/customer.js";
import { useDispatch } from "react-redux";
import { useStyles } from "../../assets/styles/components/global.js";
import { verifyTwoFactCode } from "../../actions/security";

const Page = () => {
    const router = useNavigate();
    const dispatch = useDispatch();
    const [method, setMethod] = useState("email");
    const [stateLogin, setStateLogin] = useState(1);
    const [emailLogin, setEmailLogin] = useState("");
    const classes = useStyles();
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            submit: null,
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
            password: Yup.string().max(255).required("Password is required"),
        }),
        onSubmit: async (values, helpers) => {
            try {
                dispatch(
                    validateLogin(values.email, values.password, (res) => {
                        //console.log(res);
                        if (res.error) {
                            helpers.setStatus({ success: false });
                            helpers.setErrors({ submit: res.message });
                            helpers.setSubmitting(false);
                        } else {
                            setEmailLogin(values.email);
                            if (res.data.twoFactorAuthActive === true) {
                                setStateLogin(2);
                            } else {
                                if (res.data.isAdmin) {
                                    router("/adminorderslist");
                                } else {
                                    //console.log("Navigate to");
                                    router("/packages");
                                }
                            }
                        }
                    }),
                    []
                );
            } catch (err) {
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
            }
        },
    });

    const handleSubmitPin = async (e) => {
        e.preventDefault();
        let txt = document.getElementById("pin");
        //console.log("PIN", txt.value);
        dispatch(
            verifyTwoFactCode(
                {
                    email: emailLogin,
                    pin: txt.value,
                    fromLogin: true,
                },
                null,
                null,
                (res) => {
                    if (res.error) {
                        setStateLogin(2);
                    } else {
                        if (res.data.isAdmin) {
                            router("/adminorderslist");
                        } else {
                            //console.log("Navigate to");
                            router("/picks");
                        }
                    }
                }
            ),
            []
        );
    };

    /*const handleSkip = useCallback(() => {
        auth.skip();
        router("/");
    }, [auth, router]);*/

    if (stateLogin === 1) {
        return (
            <>
                <Box
                    sx={{
                        backgroundColor: "background.paper",
                        flex: "1 1 auto",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: 550,
                            px: 3,
                            width: "100%",
                        }}
                    >
                        <div>
                            <Stack spacing={1} sx={{ mb: 3 }}>
                                <Typography variant="h4">Login</Typography>
                                <Typography color="text.secondary" variant="body2">
                                    Don&apos;t have an account? &nbsp;
                                    <Link
                                        component="button"
                                        underline="hover"
                                        variant="subtitle2"
                                        color={"black"}
                                        onClick={() => {
                                            router("/signup");
                                        }}
                                    >
                                        Register
                                    </Link>
                                </Typography>
                            </Stack>
                            {method === "email" && (
                                <form noValidate onSubmit={formik.handleSubmit}>
                                    <Stack spacing={3}>
                                        <TextField error={!!(formik.touched.email && formik.errors.email)} fullWidth helperText={formik.touched.email && formik.errors.email} label="Email Address" name="email" onBlur={formik.handleBlur} onChange={formik.handleChange} type="email" value={formik.values.email} />
                                        <TextField error={!!(formik.touched.password && formik.errors.password)} fullWidth helperText={formik.touched.password && formik.errors.password} label="Password" name="password" onBlur={formik.handleBlur} onChange={formik.handleChange} type="password" value={formik.values.password} />
                                    </Stack>
                                    {formik.errors.submit && (
                                        <Typography color="error" sx={{ mt: 3 }} variant="body2">
                                            {formik.errors.submit}
                                        </Typography>
                                    )}
                                    <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" className={classes.submit}>
                                        Continue
                                    </Button>
                                    {/*<Button fullWidth size="large" sx={{ mt: 3 }} onClick={handleSkip}>
                                        Skip authentication
                                    </Button>*/}
                                </form>
                            )}
                        </div>
                    </Box>
                </Box>
            </>
        );
    } else if (stateLogin === 2) {
        return (
            <>
                <Box
                    sx={{
                        backgroundColor: "background.paper",
                        flex: "1 1 auto",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: 550,
                            px: 3,
                            py: "100px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <form onSubmit={handleSubmitPin}>
                                <Grid container spacing={1}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ borderBottom: "none", textAlign: "center" }}>
                                                    <Typography sx={{ fontWeight: "bold", fontSize: "24px" }}>{"Security Verification"}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ borderBottom: "none", textAlign: "center" }}>
                                                    <Typography style={{ fontWeight: "bold" }}>{"Authenticador Code"}</Typography>
                                                    <TextField
                                                        id="pin"
                                                        name="pin"
                                                        inputRef={(input) => input && input.focus()}
                                                        inputProps={{
                                                            maxlength: 6,
                                                            className: classes.inputStyle,
                                                        }}
                                                    />
                                                    <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>{"Enter the 6-digit code from FlashTeller/Google Authenticator"}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Button type="submit" fullWidth variant="contained" className={classes.submit}>
                                    {"Submit"}
                                </Button>
                            </form>
                        </div>
                    </Box>
                </Box>
            </>
        );
    }
};

Page.getLayout = (page) => <AuthLayout>{page}</AuthLayout>;

export default Page;
