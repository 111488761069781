import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMarqueesIndex } from "../../actions/marquees.js";
import { logout } from "../../redux/customerSlice.js";
import { makeStyles } from "@mui/styles";
import { Box, Container, Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    marquee: {
        width: "100%",
        marginTop: "0px",
        overflowX: "auto",
        overflow: "auto",
        fontSize: "34px",
        color: "#0F4D04",
        fontWeight: "bold",
    },
    textmarquee: {
        marginLeft: "100px",
    },
}));

const MarqueeShow = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [marquees, setMarquees] = useState([]);
    const classes = useStyles();
    const [settings, setSettings] = useState({
        id: "",
        direction: "left",
        speed: 50,
        pauseOnOver: false,
    });

    let textSize = 100;
    let textColor = "blue";

    useEffect(() => {
        dispatch(
            getMarqueesIndex((data) => {
                if (!data.error) {
                    setMarquees(data.data);
                    setSettings({
                        id: data.settings._id,
                        direction: data.settings.direction,
                        speed: data.settings.speed,
                        pauseOnOver: data.settings.pauseOnOver,
                    });
                } else {
                    if (data.error) {
                        //console.log("TOKEN MURIO");
                        if (data.message === "Token Expire") {
                            dispatch(logout);
                            navigate("/");
                        }
                    }
                }
            })
        );
    }, []);

    return marquees ? (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0,
                }}
            >
                <Container maxWidth="xl">
                    <Grid container spacing={12} justifyContent="center">
                        <Grid item xs={12} sm={6} lg={12} mt={0} key={"marquees"}>
                            <div className={classes.marquee}>
                                <Marquee speed={settings.speed} pauseOnHover={settings.pauseOnOver} pauseOnClick={false} direction={settings.direction}>
                                    {marquees.map((item, key) => {
                                        return (
                                            <div style={{ fontSize: item.size, color: item.color }} className={classes.textmarquee} key={key}>
                                                {item.text}
                                            </div>
                                        );
                                    })}
                                </Marquee>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    ) : null;
};

export default MarqueeShow;
