import * as api from "../api/marquees.js";

export const getMarqueesList = (start, length, token, callback) => async (dispatch) => {
    const { data } = await api.getMarqueesList(start, length, token);
    callback(data);
};

export const updateMarqueeItem = (item, token, callback) => async (dispatch) => {
    const { data } = await api.updateMarqueeItem(item, token);
    callback(data);
};

export const updateMarqueeSettings = async (settings, token, callback) => {
    const { data } = await api.updateMarqueeSettings(settings, token);
    callback(data);
};

export const getMarqueesIndex = (callback) => async (dispatch) => {
    const { data } = await api.getMarqueesIndex();
    callback(data);
};
