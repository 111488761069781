import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, ButtonBase } from "@mui/material";

export const SideNavItem = (props) => {
    const { active = false, disabled, external, icon, path, title } = props;

    const linkProps = path
        ? external
            ? {
                  component: "a",
                  href: path,
              }
            : {
                  component: Link,
                  href: path,
              }
        : {};

    return (
        <li key={title}>
            <ButtonBase
                sx={{
                    alignItems: "center",
                    borderRadius: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                    pl: "16px",
                    pr: "16px",
                    py: "6px",
                    textAlign: "left",
                    width: "100%",
                    ...(active && {
                        backgroundColor: "rgba(0,0,0, 0.9)",
                    }),
                    "&:hover": {
                        backgroundColor: "#999999",
                    },
                }}
                {...linkProps}
            >
                {icon && (
                    <Box
                        component="span"
                        sx={{
                            alignItems: "center",
                            color: "neutral.600",
                            display: "inline-flex",
                            justifyContent: "center",
                            mr: 2,
                            ...(active && {
                                color: "#FFCC33",
                            }),
                            "&:hover": {
                                color: "white",
                            },
                        }}
                    >
                        {icon}
                    </Box>
                )}
                <Box
                    component="span"
                    sx={{
                        color: "neutral.600",
                        flexGrow: 1,
                        fontFamily: (theme) => theme.typography.fontFamily,
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: "24px",
                        whiteSpace: "nowrap",
                        ...(active && {
                            color: "common.white",
                        }),
                        ...(disabled && {
                            color: "neutral.600",
                        }),
                        "&:hover": {
                            color: "white",
                        },
                    }}
                >
                    {title}
                </Box>
            </ButtonBase>
        </li>
    );
};

SideNavItem.propTypes = {
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    external: PropTypes.bool,
    icon: PropTypes.node,
    path: PropTypes.string,
    title: PropTypes.string.isRequired,
};
