import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    username: "",
    email: "",
    packpicks: "",
    isAdmin: false,
    isLogin: false,
    token: "",
    twoFactorAuthActive: false,
    sessionId: "",
};

export const customerSlice = createSlice({
    name: "customer",
    initialState,
    reducers: {
        login: (state, action) => {
            const { name, email, isAdmin, isLogin, twoFactorAuthActive, token, session } = action.payload.data;
            state.name = name;
            state.email = email;
            state.isAdmin = isAdmin;
            state.isLogin = isLogin;
            state.token = token;
            state.twoFactorAuthActive = twoFactorAuthActive;
            state.sessionId = session;
        },
        updateTwoFactor: (state, action) => {
            const { twoFactorAuthActive } = action.payload.data;
            state.twoFactorAuthActive = twoFactorAuthActive;
        },
        logout: (state, action) => {
            state.name = null;
            state.email = null;
            state.isAdmin = null;
            state.isLogin = null;
            state.token = null;
            state.twoFactorAuthActive = null;
            state.sessionId = null;
        },
    },
});

export const { login, logout, updateTwoFactor } = customerSlice.actions;
export default customerSlice.reducer;
